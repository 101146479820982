.ResourceWrap{
    border:none;
    @media screen and (max-width: $break-ipad) {
        padding: 0px 15px;
    }
    .HeadingWrap{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        z-index: 9;
        position: relative;

        h1{
            a{
                color: #3c4b64;
                &:hover{
                    text-decoration: none;
                }
            }
            @media screen and (max-width: $break-laptoplg1440) {
                font-size: 30px;
            }
            @media screen and (max-width: $break-ipad) {
                font-size: 25px;
            }
        }
        @media screen and (max-width: $break-ipadproportrait) {
            display: block;
        }
        .BreadCRumb{
            p{
                margin-bottom: 0px;
                font-size: 16px;
                i{
                    font-size: 12px;
                    margin: 0px 5px;
                }
                a{
                    color: #000;
                }
             
            }
        }
    }
    .Subheading{
        h3{
            font-size: 20px;
        .BookMark{
            i{
                color: #f2a553;
            }
        }
        }

     
    }
    .row{
        .col-md-6{
            @media screen and (max-width: $break-ipadproportrait) {
                flex: 0 0 50%;
                max-width: 50%;
            }
            @media screen and (max-width: $break-ipad) {
                flex: 0 0 100%;
                max-width: 100%;
            }
            a{
                &:hover{
                    text-decoration: none;
                }
                .ImageBox{
                    position: relative;
                    margin-bottom: 30px;
                   
                    .ImageArea{
                        // background-color: #ddd;
                        max-height: 400px;
                        overflow: hidden;
                        border-radius: 20px;
                        img{
                            max-width: 100%;
                            max-height: 370px;
                            // @media screen and (max-width: $break-laptoplg1440) {
                            //     max-height: 200px;
                            // }
                            @media screen and (max-width: $break-ipad) {
                                max-height: none;
                            }
                        }
                        text-align: center;
                    }
                    .TextOverlay{
                        position: relative;
                       top: -30px;
                        background-color: #fff;
                        padding: 10px;
                        left: 0;
                        right: 0;
                        width: 80%;
                        margin: 0 auto;
                        border-radius: 12px;
                        text-align: center;
                        min-height: 80xpx;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        box-shadow: 0px 0px 20px 0px #ddd;
                        color: #7D8597;
                        font-size: 22px;
                        @media screen and (max-width: $break-belowlaptop) {
                            position: relative;
                            bottom: unset;
                            // width: 100%;
                            min-height: 60px;
                        }
                    }
                }
            }
          
        }
    }
    .VideoListWrap{
       
          .BoxWrap{
              margin-bottom: 30px;
              a{
                  color: #7D8597;
                  &:hover{
                      text-decoration: none;
                  }
                  .ImageWrap{
                      position: relative;
                    
                    img{
                        max-width: 100%;
                        height: 260px;
                        object-fit: cover;
                        @media screen and (max-width: $break-abovelaptop){
                            height: 180px;
                        }
                    }
                    .PlayIcon{
                        position: absolute;
                        top: 35%;
                        left: 0;
                        right: 0;
                        width: 100%;
                        text-align: center;
                        @media screen and (max-width: $break-laptoplg1440) {
                            top: 25%;
                            img{
                                max-width: 100px;
                            }
                        }
                    }
                  }
                 
                  h3{
                      margin-top: 20px;
                      font-size: 25px;
                      font-weight: 400;
                    @media screen and (max-width: $break-belowlaptop) {
                        font-size: 20px;
                    }
                  }
              }
          }
          &.imagesList{
            min-height: 800px;
            overflow-y: auto;
            
            overflow-x: hidden;
          }      
    }
    .ViedoDetail{
        position: relative;
        .ImageWrap{
            position: relative;
            margin-bottom: 20px;
          img{
              max-width: 100%;
          }
        }
        p{
            font-size: 16px;
            color: #7D8597;
        }
        &:after{
            position: absolute;
            content: '';
            height: 80px;
            width: 80px;
            background-image: url('../assets/inclinetrilite.png');
            bottom: 0px;
            right: 10%;
            background-size: contain;
            background-repeat: no-repeat;
            
        }
        .header{
            .main-nav{
                display: none;
            }
        }
        .footer{
            display: none;
        }
    }
    .PublicationList {
        position: relative;
        .row{
            position: relative;
            &:after{
                position: absolute;
                content: '';
                height: 80px;
                width: 80px;
                background-image: url('../assets/inclinetrilite.png');
                bottom: -180px;
                right: 20%;
                background-size: contain;
                background-repeat: no-repeat;
                
            }
            &:before{
                position: absolute;
                content: '';
                height: 50px;
                width: 50px;
                background-image: url('../assets/polyleftpatch.png');
                bottom: -80px;
                left: 30%;
                background-size: contain;
                background-repeat: no-repeat;
               
            }
        }
      
        .publicationBlock{
            a{
                color: #7D8597;
                .ImageWrap{
                    margin-bottom: 20px;
                    img{
                        max-width: 100%;
                    }
                }
                &:hover{
                    text-decoration: none;
                }
            }
           
            @media screen and (max-width: $break-ipad) {
                text-align: center;
            }
            
        }
    }
   
    .SliderWrap{
        width: 600px;
        max-width: 100%;
        margin: 0 auto;
        z-index: 9;
        box-shadow: 0px 2px 20px 0px #ddd;
        border-radius: 20px;
        padding: 20px;
        margin-bottom: 30px;
        background-color: $white;
        &:after{
            content: '';
            height: 150px;
            width: 150px;
            position: absolute;
            bottom: -150px;
            background-size: contain;
            background-repeat: no-repeat;
            left: -80px;
            background-image: url('../assets/polyleft.png');
            z-index: -1;
        }
        .carousel-indicators{
             display: none;
            bottom: 140px;
            li{
                background-color: $dargreen;
                height: 10px;
            }
            @media screen and (max-width: $break-belowlaptop) {
                display: flex;
            }
        }
        .carousel-inner{
            .carousel-item{
                .carousel-caption{
                    position: relative;
                    left: unset;
                    color: #000;
                    right: unset;
                    text-align: left;
                    margin-top: 20px;
                    h3{
                        color: #334565;
                        font-size: 20px;
                    }
                    p{
                        color: #334565;
                        font-size: 20px;
                    }
                }
            }
        }
        .carousel-control-next{
            right: -100px;
            cursor: pointer;
            span{
                background-image: url('../assets/slidearrowright.png');
                width: 45px;
                height: 54px;
            }
            @media screen and (max-width: $break-belowlaptop) {
                display: none;
            }
        }
        .carousel-control-prev{
            left: -100px;
            cursor: pointer;
            span{
                background-image: url('../assets/slidearrowleft.png');
                width: 45px;
                height: 54px;
            }
            @media screen and (max-width: $break-belowlaptop) {
                display: none;
            }
        }
    }
    .FilterDropdown{
        float: left;
    }
}
.presentation-img {
    max-width: 100%;
    min-width: 100%;
}
.presentation-content {
    padding: 8px 0;
    text-align: center;
}
p.FilterDropdown {
    
    .form-group {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        label {
            margin-right: 10px;
            margin-bottom: 2px;
        }
    select {
        border-radius: 100px;
        border-color: #3c4b64;
        max-width: 200px;
        padding-right: 30px;
    }
    }
}
.line-break-review {
    @media (max-width:$break-belowlaptop) {
        display: block !important;
    }
}
.DetailImage{
    padding: 0px 15px;
    .ImageDetailBlock{
        .mt-12{
            margin-top: 20px;
        }
        
        .ImageWrap{
            img{
                width: 100%;
            }
        }
        p{
            font-size: 16px;
        }
    }
}
.publicationBlock{
  
    &.PublicationDetail{
        .ImageWrap{
            margin-bottom: 20px;
            
            img{
                max-height: 300px;
                max-width: 300px;
            }
        }
    }
}