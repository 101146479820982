.QuizBox {
  h3 {
    text-align: center;
    padding-top: 20px;
  }
  h6 {
    padding-top: 20px;
  }
  .card {
    max-width: 1000px;
    margin: 10px auto;
    padding: 0px;
    h4 {
      text-align: center;
      padding: 20px;
      border-bottom: 1px solid #ddd;
      i{
        font-size: 30px;
      }
    }
    h6 {
      text-align: center;
      padding-top: 20px;
    }
    .card-body {
      padding: 15px;
      table {
        width: 300px;
        margin: 0 auto;
        max-width: 100%;
        tr {
          td {
            padding: 10px;
            font-size: 16px;
            width: 50%;
            text-align: center;
          }
        }
      }
      p {
        font-size: 16px;
      }
      .ButtonWrap {
        text-align: center;
        margin-top: 20px;
        button {
          background-color: #78af4d;
          color: $white;
          font-size: 20px;
          border-color: $dargreen;
          width: 120px;
          height: 40px;
          border-radius: 6px;
          font-size: 16px;
          margin-top: 10px;
          &:nth-child(1) {
            margin-right: 10px;
          }
          &:focus {
            box-shadow: none;
            outline: none;
          }
        }
      }
      .SelectOption {
        padding: 0px;
      
        li {
          list-style-type: none;
          font-size: 14px;
          color: #3c4b64;
          .custom-radio {
            width: 100%;
            padding-left: 0px;
            margin-bottom: 10px;

            label {
              width: 100%;
              position: relative;
              padding: 5px;
              cursor: pointer;
              margin: unset;
              font-size: 14px;
              padding-left: 40px;
              &:before,
              &:after {
                left: 8px;
                top: 8px;
              }
              &:before {
                background-color: $white;
                border-color: $dargreen;
              }
            }
          }
          .custom-radio
            .custom-control-input:checked
            ~ .custom-control-label::after {
            background-image: none;
            background-color: #78af4d;
            border-radius: 50%;
            height: 10px;
            width: 10px;
            left: 11px;
            top: 11px;
          }
          .custom-control-input:checked ~ .custom-control-label::before {
            box-shadow: none;
          }
        }
      }
      &.ResultWrap{
          position: relative;
          text-align: center;
          h5{
             
              font-size: 20px;
              margin: 20px 0px;
              &.success{
                color: #10a510;
              }
              &.fail{
                  color: red;
              }
          }
          .LinkBox{
              padding: 0px;
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              align-items: center;
              li{
                  list-style-type: none;
                  button{
                      color: #78af4d;
                      font-weight: 500;
                      font-size: 16px;
                      font-size: 16px;
                      margin: 0px 10px;
                      border:none;
                      box-shadow: none;
                      &:focus{
                          border:none;
                          box-shadow: none;
                      }
                  }
              }
          }
      }
    }
  }

  @media screen and (max-width:$break-mobile580) {
    h3 {
      text-align: left;
      font-size: 1.25rem;
    }
    h6 {
      padding-top: 10px;
    }
    }
}
.course-evaluation-container{
  margin-bottom: 3rem;
  .card{
    max-width: 1250px;
    box-shadow: 0px 3px 5px 3px #ececec;
    padding: 20px 30px;
    margin-top: 0px;
    border-radius: 10px;
    position: relative;
    border: none;
    .card-body{
      padding: 1rem 0;
    }
    h4{
      font-size: 30px;
    }
    .formio-form{
      .col-form-label{
        color: #3c4b64;
        margin: 10px 0px;
        font-size: 16px;
        padding: 0;
      }
      input.form-control{
        border-color: #707070;
        height: 60px;
        border-radius: 6px;
        box-shadow: 0px 3px 5px 3px #ececec;
        background-color: #fff;
        width: 100%;
        border: none;
        padding: 10px;
      }
      .btn-primary {
        background-color: #78af4d;
        color: #fff;
        font-size: 20px;
        border-color: #78af4d;
        width: 200px;
        height: 60px;
        border-radius: 6px;
        cursor: pointer;
        margin-top: 1.5rem;
      }
      .checkbox{
        margin-top: 1.5rem;
        .form-check-label{
          span{
            color: #3c4b64;
            font-size: 16px;
          }
        }
        .form-check-input{
          height: 15px;
          width: 15px;
          margin-right: 5px;
          border: 1px solid #78af4d;
          box-shadow: none;
        }
      }
      .form-radio{
        font-size: 16px;
        color: #3c4b64;
        .form-check-input{
          height: 15px;
          width: 15px;
          margin-right: 5px;
          border: 1px solid #78af4d;
          box-shadow: none;
        }
      }
      .formio-component-radio{
        margin-bottom: 8px;
        .col-form-label{
          margin-top: 0;
        }
      }
    }
  }
}
@media screen and (max-width:767px){
  .course-evaluation-container{
    .card{
      h4{
        font-size: 25px;
      }
    }
  }
}
