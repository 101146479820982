.CeuWrap{
    border:none;
    .HeadingWrap{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @media screen and (max-width: $break-mobile) {
            display: block;
        }
        @media screen and (max-width: $break-ipadprolandscape) {
          display: flex !important;
      }
        .BreadCRumb{
            display: flex;
            // flex: 0 0 30%;
            align-items: center;
            justify-content: flex-end;
            @media screen and (max-width: $break-mobile) {
               margin: 20px 0px;
               flex: 0 0 100%;
               justify-content: flex-start;
            }
            label{
                margin-bottom: 0px;
                margin-right: 10px;
            }
            .SelectWrap{
                position: relative;
                select{
                    width: 150px;
                    border-radius: 100px;
                    -webkit-appearance: none;
                    background:none;
                    position: relative;
                    z-index: 9;
                    &:focus{
                        outline: none;
                        box-shadow: none;
                    }
                }
               
                &:after {
                    content: "";
                    width: 12px;
                    height: 10px;
                    background-image: url("../assets/down-filled-triangular-arrow.png");
                    background-size: contain;
                    right: 20px;
                    top: 14px;
                    position: absolute;
                    background-repeat: no-repeat;
                 }
            }
           
        }
        .BreadCrumbCEU{
            p{
                margin-bottom: 0px;
                font-size: 16px;
                i{
                    font-size: 12px;
                    margin: 0px 5px;
                }
                a{
                    color: #000;
                }
                &.FilterDropdown{
                    .form-group{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        label{
                            margin-bottom: 0px;
                            margin-right: 10px;
                            min-width: 75px;
                            font-weight: 500;
                        }
                        select{
                            border-radius: 100px;
                            border-color: #3c4b64;
                            max-width: 140px;
                            &:focus{
                                box-shadow: none;
                            }
                        }
                    }
                    
                }
            }
        }
    }
    .Subheading{
        h3{
            font-size: 20px;
        }

     
    }
    .DataWrap{
        ul{
            padding: 0px;
            li{
                list-style-type: none;
                font-size: 16px;
            }
        }
        .TableWrap{
          max-height: 550px;
            @media screen and (max-width: $break-ipad) {
                overflow-x: auto;
            }
            @media screen and (max-width: $break-ipad880) {
              overflow-x: scroll;
              overflow-y: visible;
              min-height: 200px;
              max-height: 550px;
            }
            @media screen and (max-width: $break-mobile) {
              max-height: 550px;
            }
            position: relative;
            &:before{
                content: '';
                width: 138px;
                height: 87px;
                background-image: url('../assets/ovalpatch.png');
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                right: 0;
                margin: 0 auto;
                top: 50%;
                left: -40%;
            }
            &:after{
                content: '';
                width: 80px;
                height: 80px;
                background-image: url('../assets/inclinetrilite.png');
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                left: 0;
                margin: 0 auto;
                top: 50%;
                right: -40%;
            }
            table{
                width: 100%;
                box-shadow: 0px 3px 5px 3px #ececec;
                border-radius: 20px;
                @media screen and (max-width: $break-ipad) {
                   width: 1024px;
                }
                tr{
                    th{
                        font-size: 16px;
                        padding: 15px;
                        font-weight: 400;
                      
                    }
                    td{
                        font-size: 16px;
                        padding: 5px 15px;
                       
                        button{
                            box-shadow: none;
                                border: none;
                            &:focus{
                                outline: none;
                                box-shadow: none;
                                border: none;
                            }
                        }
                    }
                }
            }
        }
        .ceu-table-wrap{
          // overflow: auto;
        }
       
        .ButtonWrap{
            margin-top: 20px;
            button{
                background-color: #78af4d;
                color: $white;
                font-size: 20px;
                border-color: $dargreen;
                width: 200px;
                height: 60px;
                border-radius: 6px;
                margin-bottom: 20px;
                
                &:nth-child(1){
                    margin-right: 10px;
                }
                @media screen and (max-width: $break-ipad) {
                  display: block;
                  height: 40px;
                  width: 150px;
                  max-width: 100%;
                  font-size: 16px;
                }
            }
        }
    }
    .FormWrapCEU{
      
        form{
            box-shadow: 0px 2px 20px 0px #ddd;
            border-radius: 20px;
            padding: 30px 40px;
            margin-top: 30px;
            .row {
                div {
                  label {
                    color: #3c4b64;
                    display: block;
                    margin: 10px 0px;
                    font-size: 16px;
                  }
                  input,
                  select {
                    border-color: #707070;
                    height: 60px;
                    border-radius: 6px;
                    box-shadow: 0px 3px 5px 3px #ececec;
                    background-color: #fff;
                    width: 100%;
                    border: none;
                    padding: 10px;
                    @include placeholder {
                      color: #aaaaaa;
                    }
                    &:focus {
                      outline: none;
                      border: none;
                    }
                  }
                  select {
                    -webkit-appearance: none;
                    position: relative;
                    z-index: 999;
                    background-color: transparent;
                  }
                  .SelectWrap {
                    position: relative;
                    &:after {
                      content: "";
                      width: 20px;
                      height: 10px;
                      background-image: url("../assets/down-filled-triangular-arrow.png");
                      background-size: contain;
                      right: 20px;
                      top: 60px;
                      position: absolute;
                      background-repeat: no-repeat;
                    }
                  }
                  .react-datepicker-wrapper{
                    width: 100%;
                  }
                  .checkWrapStep {
                    font-size: 16px;
            
                    label {
                      cursor: pointer;
                      a {
                        color: #78af4d;
                        &:hover {
                          text-decoration: none;
                        }
                      }
                      &:before {
                        top: 5px;
                      }
                      &:after {
                        top: 5px;
                      }
                    }
            
                    .custom-control-input {
                      &:checked ~ .custom-control-label {
                        &:before {
                          background-color: #78af4d;
                          border-color: #78af4d;
                        }
                      }
                      &:focus {
                        &:not(:checked) ~ .custom-control-label {
                          &:before {
                            border-color: #78af4d;
                          }
                        }
                      }
                      &:focus ~ .custom-control-label {
                        &:before {
                          box-shadow: none;
                        }
                      }
                    }
                  }
                  .FileSelector{
                      .dzu-previewContainer{
                          padding: 0px;
                          .dzu-previewStatusContainer{
                              progress{

                              }
                          }
                      }
                      .dzu-dropzone{
                          min-height: 60px;
                          overflow: unset;
                          border-radius: 6px;
                          box-shadow: 0px 3px 5px 3px #ececec;
                          background-color: #fff;
                          width: 100%;
                          border: none;
                          padding: 10px;
                          label{
                              display: flex;
                              flex-wrap: wrap;
                              align-items: center;
                              p{
                                  text-align: center;
                                  margin-bottom: 0px;
                                  font-weight: 400;
                                  font-size: 14px;
                                  svg{
                                      height: 15px;
                                      width: 15px;
                                      margin-right: 10px;
                                  }
                                  span{
                                      color: $dargreen;
                                  }
                              }
                          }
                          .dzu-inputLabelWithFiles{
                              background-color: $dargreen;
                              color: $white;
                              border:none;
                              box-shadow: none;
                              font-weight: 500;
                              font-size: 13px;
                          }
                      }
                      .dzu-submitButtonContainer{
                          margin: 5px 0px;
                          button{
                            background-color: $dargreen;
                            color: $white;
                            border:none;
                            box-shadow: none;
                            font-weight: 500;
                            font-size: 13px;
                          }
                      }
                  }
                }
                .PathTop{
                   
                    &:after{
                        content: '';
                        height: 50px;
                        width: 50px;
                        background-image: url('../assets/circlepatch.png');
                        background-size: contain;
                        background-repeat: no-repeat;
                        position: absolute;
                        right: 50%;
                        top: 20px;
                       
                    }
                    input{
                        z-index: 1;
                        background-color: #fff;
                        position: relative;
                    }
                }
                .patchTopMost{
                    &:after{
                        content: '';
                        height: 50px;
                        width: 50px;
                        background-image: url('../assets/polytoppatch.png');
                        background-size: contain;
                        background-repeat: no-repeat;
                        position: absolute;
                        right: 10%;
                        top: -130%;
                       
                    }
                    .react-datepicker-popper {
                      z-index: 999 !important;
                    }
                }
                .patchBottom{
                    &:after{
                        content: '';
                        height: 100px;
                        width: 100px;
                        background-image: url('../assets/patchbitepink.png');
                        background-size: contain;
                        background-repeat: no-repeat;
                        position: absolute;
                        right: 50%;
                        bottom: -100%;
                    }
                   
                }
              }
              .ButtonWrap{
                  text-align: center;
                  margin-top: 30px;
                  button{
                    background-color: #78af4d;
                    color: $white;
                    font-size: 20px;
                    border-color: $dargreen;
                    width: 200px;
                    height: 60px;
                    border-radius: 6px;
                    margin-bottom: 20px;
            
                    @media screen and (max-width: $break-ipad) {
                      margin-top: 30px;
                      display: block;
                      height: 40px;
                      width: 150px;
                      max-width: 100%;
                      font-size: 16px;
                    }
                  }
              }
            }
            @media screen and (max-width:$break-mobile) {
              form {
                padding: 20px;
                border-radius: 10px;
              }
            }
      
    }
    .CertificatePreview{
        box-shadow: 0px 2px 20px 0px #ddd;
        border-radius: 20px;
        padding: 30px 40px;
        position: relative;
        margin-top: 30px;
        background-color: #fff;
        z-index: 9;
        &:before{
            content: '';
            position: absolute;
            height: 80px;
            width: 80px;
            left: -40px;
            background-image: url('../assets/patchbitepink.png');
            background-size: contain;
            background-repeat: no-repeat;
            top: -30px;
        }
        &:after{
            content: '';
            position: absolute;
            height: 50px;
            width: 50px;
            right: 10px;
            background-image: url('../assets/polyleftpatch.png');
            background-size: contain;
            background-repeat: no-repeat;
            top: 56px;
        }
        .imgWrap{
            text-align: center;
            margin-top: 20px;
            position: relative;
            &:before{
                content: '';
                position: absolute;
                height: 80px;
                width: 80px;
                left: -40px;
                background-image: url('../assets/patchbitepink.png');
                background-size: contain;
                background-repeat: no-repeat;
                bottom: -50px;
            }
            &:after{
                content: '';
                position: absolute;
                height: 50px;
                width: 50px;
                right: 10px;
                background-image: url('../assets/polyleftpatch.png');
                background-size: contain;
                background-repeat: no-repeat;
                bottom: -94px;
            }
            img{
                max-width: 100%;
            }
        }
        .ButtonWrap{
            text-align: center;
            margin-top: 30px;
            button{
              background-color: #78af4d;
              color: $white;
              font-size: 20px;
              border-color: $dargreen;
              width: 250px;
              height: 60px;
              border-radius: 6px;
              margin:10px 5px;
                max-width: 100%;
                &:focus{
                    outline: none;
                    box-shadow: none;
                }
            }
        }
    }
    .CertificateWrap{
        box-shadow: 0px 2px 20px 0px #ddd;
        border-radius: 20px;
        padding: 30px 40px;
        position: relative;
        margin-top: 30px;
        background-color: #fff;
        z-index: 9;
        &:before{
            content: '';
            position: absolute;
            height: 50px;
            width: 50px;
            left: 17%;
            background-image: url('../assets/circlepatch.png');
            background-size: contain;
            background-repeat: no-repeat;
            top: 40px;
        }
        &:after{
            content: '';
            position: absolute;
            height: 50px;
            width: 50px;
            right: 30%;
            background-image: url('../assets/polytoppatch.png');
            background-size: contain;
            background-repeat: no-repeat;
            top: 40px;
        }
        ul{
            padding: 0px;
            display: flex;
            flex-wrap: wrap;
            position: relative;
            &:before{
                content: '';
                position: absolute;
                height: 50px;
                width: 50px;
                left: 17%;
                background-image: url('../assets/polyleftpatch.png');
                background-size: contain;
                background-repeat: no-repeat;
                bottom: 40px;
            }
            &:after{
                content: '';
                position: absolute;
                height: 50px;
                width: 50px;
                right: 30%;
                background-image: url('../assets/inclinetrilite.png');
                background-size: contain;
                background-repeat: no-repeat;
                bottom: 40px;
            }
            li{
                list-style-type: none;
                flex:0 0 25%;
                max-width: 25%;
                margin-bottom: 15px;
                @media screen and (max-width: $break-mobile) {
                    flex: 0 0 50%;
                    max-width: 50%;
                }
                a{
                    color: #959dab;
                    font-size: 18px;
                    &:hover{
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .choose-file {
      cursor:pointer;
      padding: 18px 10px !important;
    }
}

.checkWrapStep {
  font-size: 16px;

  label {
    cursor: pointer;
    a {
      color: #78af4d;
      &:hover {
        text-decoration: none;
      }
    }
    &:before {
      top: 5px;
    }
    &:after {
      top: 5px;
    }
  }

  .custom-control-input {
    &:checked ~ .custom-control-label {
      &:before {
        background-color: #78af4d;
        border-color: #78af4d;
      }
    }
    &:focus {
      &:not(:checked) ~ .custom-control-label {
        &:before {
          border-color: #78af4d;
        }
      }
    }
    &:focus ~ .custom-control-label {
      &:before {
        box-shadow: none;
      }
    }
  }
 
}
.react-datepicker{
  select{
    -webkit-appearance: auto !important;
    height: auto !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }
  .react-datepicker__navigation{
    top: 8px !important;
    &:focus, &:active{
      box-shadow: none !important;
      border:none !important;
      outline: none !important;
    }
  }
  .react-datepicker__triangle{
    &:after, &:before{
      left: -14px !important;
    }
  }
}