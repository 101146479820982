.BannerWrap{
    display: flex;
    min-height: 400px;
    justify-content: center;
    align-items: center;
    // background-color: #5f5f5f;
    // margin-bottom: 20px;
    // background-image: url('../assets/banner.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position:100%;
    @media screen and (max-width: $break-ipadprolandscape) {
        margin-bottom: 20px;
    }
        @media screen and (max-width: $break-ipad) {
        min-height:200px;
    }
    h1{
        color: $white;
        font-weight: 400;
        text-align: center;
        font-size: 4rem;
        span{
            font-weight: bold;
            @media screen and (max-width: $break-ipad){
                     
                text-align: center;
            }
        }
        @media screen and (max-width: $break-ipad){
            font-size: 25px;
            text-align: center;
        }
    }
    p{
        color: $white;
        &.breadcrumb{
            margin-bottom: 0px;
            border:none;
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            i{
                margin:0px 10px;
                padding-top: 4px;
                font-size: 10px;
            }
        }
        a{  
            color: $white;
            &:hover{
                text-decoration: none;
            }
        }
    }
    &.BannerLayout{
        justify-content: flex-end;
        position: relative;
        
        &:after{
            content: '';
            height: 350px;
            width: 350px;
            background-image: url('../assets/cardiology.png');
            background-size: contain;
            right: 160px;
            bottom: -200px;
            z-index: 9;
            position: absolute;
        }
        .HeadingWrap{
            h1{
                font-size: 30px;
                text-align: right;
                padding: 10px 35px;
                max-width: 1120px;
                margin-left: auto;
                font-weight: 600;
                @media screen and (max-width: $break-ipad){
                    font-size: 17px;
                    text-align: center;
                }
                span{
                    display: block;
                    text-align: right;
                    font-weight: 600;
                    font-size: 20px;
                    margin-top: 20px;
                    @media screen and (max-width: $break-ipad){
                     
                        text-align: center;
                    }
                }
            }
        }
    }
}