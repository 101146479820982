.ReferrelWrap{
    border:none;
    .HeadingWrap{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        z-index: 9;
        position: relative;

        h1{
            a{
                color: #3c4b64;
                &:hover{
                    text-decoration: none;
                }
            }
            @media screen and (max-width: $break-laptoplg1440) {
                font-size: 30px;
            }
            @media screen and (max-width: $break-ipad) {
                font-size: 25px;
            }
        }
        @media screen and (max-width: $break-ipadprolandscape) {
            display: block;
        }
        .BreadCRumb{
            p{
                margin-bottom: 0px;
                font-size: 16px;
                i{
                    font-size: 12px;
                    margin: 0px 5px;
                }
                a{
                    color: #000;
                }
             
            }
        }
    }
    .Subheading{
        h3{
            font-size: 20px;
        .BookMark{
            i{
                color: #f2a553;
            }
        }
        }

     
    }
    .DataWrap{
        box-shadow: 0px 2px 20px 0px #ddd;
        border-radius: 20px;
        padding: 20px;
        margin-bottom: 30px;
        position: relative;
        &:after{
            content: '';
            height: 250px;
            width: 250px;
            position: absolute;
            bottom: -190px;
            background-image: url('../assets/polyleft.png');
            background-size: contain;
            background-repeat: no-repeat;
            left: -40px;
        }
        &:before{
            content: '';
            height: 70px;
            width: 70px;
            position: absolute;
            top: 30px;
            background-image: url('../assets/circlepatch.png');
            background-size: contain;
            background-repeat: no-repeat;
            left: 130px;
        }
        .ContentBox{
            position: relative;
            &:after{
                content: '';
                position: absolute;
                height: 80px;
                width: 80px;
                right: 20%;
                background-image: url('../assets/polytoppatch.png');
                background-size: contain;
                background-repeat: no-repeat;
                top: 20px;
            }
            h3{
                font-size: 16px;
                padding-bottom: 10px;
                border-bottom: 1px solid $dargreen;
                width: max-content;
                margin-bottom: 20px;
            }
            p{
                border-bottom: 2px solid #ddd;
                padding-bottom: 10px;
                font-size: 15px;
            }
        }
        .SocialBox{
            position: relative;
            z-index: 9;
            h3{
                font-size: 16px;
                padding-bottom: 10px;
                border-bottom: 1px solid $dargreen;
                width: max-content;
                margin-bottom: 20px;
            }
            ul{
                padding: 0px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                @media screen and (max-width: $break-MDPI) {
                    display: block;
                }
                li{
                    list-style-type: none;
                    display: flex;
                    align-items: center;
                    @media screen and (max-width: $break-mobile580) {
                        display: block;
                    }
                    .SocialIcon{
                       margin-right: 10px;
                       @media screen and (max-width: $break-mobile580) {
                           margin-right: 0px;
                           margin-bottom: 20px;
                       }
                        h4{
                            font-size: 14px;
                        
                        }
                        .iconWrap{
                            display: flex;
                            a{
                            
                                margin-right: 5px;
                                display:flex;
                                justify-content: center;
                                align-items: center;
                                width: 40px;
                                height: 40px;
                                border-radius: 6px;
                                i{
                                    color: $white;
                                    font-size: 22px;
                                }
                                &:nth-child(1){
                                    background: linear-gradient(
                                        45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
                                }
                                &:nth-child(2){
                                    background: #3b5998;
                                }
                                &:nth-child(3){
                                    background: #FF0000;
                                }
                                &:nth-child(4){
                                    background: #1DA1F2;
                                }
                                &:hover{
                                    text-decoration: none;
                                }
                            }
                        }
                        
                    }
                    .EmailIcon{
                        margin-left: 0px;
                        text-align: center;
                        margin-right: 10px;
                        height: unset !important;
                        @media screen and (max-width: $break-mobile580) {
                           margin-left: 0px;
                           text-align: left;
                           margin-right: 0px;
                           margin-bottom: 20px;
                        }
                      
                        h4{
                            font-size: 14px;
                            margin-bottom: 10px;
                        }
                        a{
                            display:flex;
                            justify-content: center;
                            align-items: center;
                            width: 40px;
                            height: 40px;
                            background: #78af4d;
                            border-radius: 6px;
                            i{
                                color: $white;
                                font-size: 22px;
                            }
                            &:hover{
                                text-decoration: none;
                            }
                        }
                    }
                    .ShareIcon{
                        margin-left: 0px;
                        @media screen and (max-width: $break-mobile580) {
                            margin-left: 0px;
                         }
                        h4{
                            font-size: 14px;
                        }
                        a{
                            display:flex;
                            justify-content: center;
                            align-items: center;
                            width: 40px;
                            height: 40px;
                            background: #78af4d;
                            border-radius: 6px;
                            i{
                                color: $white;
                                font-size: 22px;
                            }
                            &:hover{
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }
        .ButtonWrap{
            margin-top: 20px;
          
            button{
                background-color: #78af4d;
                color: $white;
                font-size: 16px;
                border-color: $dargreen;
                width: 150px;
                height: 45px;
                border-radius: 6px;
                @media screen and (max-width: $break-mobile580) {
               
                    margin-right: 0px;
                    margin-bottom: 20px;
                 }
                
               
                
            }
        }
    }
    .FormWrap {
        form {
          box-shadow: 0px 3px 5px 3px #ececec;
          padding: 20px 30px;
          margin-top: 30px;
          border-radius: 10px;
          position: relative;
          z-index: 9;
          background: #fff;
          @media screen and (max-width: $break-mobile) {
            padding: 20px;
          }
          .row {
            div {
              label {
                color: #3c4b64;
                display: block;
                margin: 10px 0px;
                font-size: 16px;
                span{
                    &.required{
                        color: red;
                    }
                }
              }
              input,
              select, textarea {
                border-color: #707070;
                height: 60px;
                border-radius: 6px;
                box-shadow: 0px 3px 5px 3px #ececec;
                background-color: #fff;
                width: 100%;
                border: none;
                padding: 10px;
                z-index: 2;
                @include placeholder {
                  color: #aaaaaa;
                }
                &:focus {
                  outline: none;
                  border: none;
                }
              }
              textarea{
                  height: 100px;
                  z-index: 2;
              }
              select {
                -webkit-appearance: none;
                position: relative;
                z-index: 99;
                background-color: transparent;
              }
              .SelectWrap {
                position: relative;
                &:after {
                  content: "";
                  width: 20px;
                  height: 10px;
                  background-image: url("../assets/down-filled-triangular-arrow.png");
                  background-size: contain;
                  right: 20px;
                  top: 60px;
                  position: absolute;
                  z-index: 9;
                  background-repeat: no-repeat;
                }
                @media screen and (max-width: $break-mobile) {
                    &::after {
                      right: 10px;
                    }
                    select {
                      padding-right: 35px;
                    }
                  }
              }
              .FileSelector{
                .dzu-previewContainer{
                    padding: 0px;
                    .dzu-previewStatusContainer{
                        progress{

                        }
                    }
                }
                .dzu-dropzone{
                    min-height: 60px;
                    overflow: unset;
                    border-radius: 6px;
                    box-shadow: 0px 3px 5px 3px #ececec;
                    background-color: #fff;
                    width: 100%;
                    border: none;
                    padding: 10px;
                    label{
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        p{
                            text-align: center;
                            margin-bottom: 0px;
                            font-weight: 400;
                            font-size: 14px;
                            color: #AAAAAA;
                            svg{
                                height: 15px;
                                width: 15px;
                                margin-right: 10px;
                            }
                            span{
                                color: $dargreen;
                            }
                        }
                       
                    }
                    .dzu-inputLabelWithFiles{
                        background-color: $dargreen;
                        color: $white;
                        border:none;
                        box-shadow: none;
                        font-weight: 500;
                        font-size: 13px;
                    }
                }
                .dzu-submitButtonContainer{
                    margin: 5px 0px;
                    button{
                      background-color: $dargreen;
                      color: $white;
                      border:none;
                      box-shadow: none;
                      font-weight: 500;
                      font-size: 13px;
                    }
                }
            }
            .BackPatch{
                &:before{
                    position: absolute;
                    content: '';
                    height: 70px;
                    width: 70px;
                    background-image: url('../assets/circlepatch.png');
                    top: 0;
                    left: 120px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    z-index: -1;
                }
               
            }
            .patchtop{
                &:before{
                    position: absolute;
                    content: '';
                    height: 70px;
                    width: 70px;
                    background-image: url('../assets/polytoppatch.png');
                    top: -100px;
                    right: 60px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    z-index: -1;
                }
            }
            .rightpatch{
                &:before{
                    position: absolute;
                    content: '';
                    height: 50px;
                    width: 50px;
                    background-image: url('../assets/polyleftpatch.png');
                    top: 20px;
                    right: 50%;
                    background-size: contain;
                    background-repeat: no-repeat;
                    z-index: -1;
                }
                &:after{
                    position: absolute;
                    content: '';
                    height: 70px;
                    width: 70px;
                    background-image: url('../assets/inclinetrilite.png');
                    bottom: 10px;
                    right: 20%;
                    background-size: contain;
                    background-repeat: no-repeat;
                    z-index: -1;
                }
            }
            .notify{
                font-size: 12px;
                text-align: left;
            }
            }
          }
          .SocialBox{
            position: relative;
            z-index: 9;
            h3{
                font-size: 16px;
                padding-bottom: 10px;
                border-bottom: 1px solid $dargreen;
                width: max-content;
                margin-bottom: 20px;
            }
            ul{
                padding: 0px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                @media screen and (max-width: $break-MDPI) {
                    display: block;
                }
                li{
                    list-style-type: none;
                    display: flex;
                    align-items: center;
                    @media screen and (max-width: $break-mobile580) {
                        display: block;
                    }
                    .SocialIcon{
                       margin-right: 10px;
                       @media screen and (max-width: $break-mobile580) {
                           margin-right: 0px;
                           margin-bottom: 20px;
                       }
                        h4{
                            font-size: 14px;
                        
                        }
                        .iconWrap{
                            display: flex;
                            a{
                            
                                margin-right: 5px;
                                display:flex;
                                justify-content: center;
                                align-items: center;
                                width: 40px;
                                height: 40px;
                                border-radius: 6px;
                                i{
                                    color: $white;
                                    font-size: 22px;
                                }
                                &:nth-child(1){
                                    background: linear-gradient(
                                        45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
                                }
                                &:nth-child(2){
                                    background: #3b5998;
                                }
                                &:nth-child(3){
                                    background: #FF0000;
                                }
                                &:nth-child(4){
                                    background: #1DA1F2;
                                }
                                &:hover{
                                    text-decoration: none;
                                }
                            }
                        }
                        
                    }
                    .EmailIcon{
                        margin-left: 0px;
                        text-align: center;
                        margin-right: 10px;
                        height: unset !important;
                        @media screen and (max-width: $break-mobile580) {
                           margin-left: 0px;
                           text-align: left;
                           margin-right: 0px;
                           margin-bottom: 20px;
                        }
                      
                        h4{
                            font-size: 14px;
                            margin-bottom: 10px;
                        }
                        a{
                            display:flex;
                            justify-content: center;
                            align-items: center;
                            width: 40px;
                            height: 40px;
                            background: #78af4d;
                            border-radius: 6px;
                            i{
                                color: $white;
                                font-size: 22px;
                            }
                            &:hover{
                                text-decoration: none;
                            }
                        }
                    }
                    .ShareIcon{
                        margin-left: 0px;
                        @media screen and (max-width: $break-mobile580) {
                            margin-left: 0px;
                         }
                        h4{
                            font-size: 14px;
                        }
                        a{
                            display:flex;
                            justify-content: center;
                            align-items: center;
                            width: 40px;
                            height: 40px;
                            background: #78af4d;
                            border-radius: 6px;
                            i{
                                color: $white;
                                font-size: 22px;
                            }
                            &:hover{
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }
        .ButtonWrap{
            margin-top: 20px;
            button{
                background-color: #78af4d;
                color: $white;
                font-size: 16px;
                border-color: $dargreen;
                width: 150px;
                height: 45px;
                border-radius: 6px;
               
                
               
                
            }
            
        }
        
        }
      }
}
.CopyAddon{
    width: 30px !important;
    height: 36px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    background-color: transparent !important;
    color: #000 !important;
    padding: 0px;
    border-color: #d8dbe0 !important;
    &:focus{
        background-color: #78af4d !important;
        border-color: #78af4d !important;
        box-shadow: none;
        outline: none;
        i{
            color: #fff !important;
        }
    }
}