.ProfileWrap {
    border: none;
    .profileCard {
        box-shadow: 0px 3px 5px 3px #ececec;
        padding: 20px;
        border-radius: 20px;
        .ImageWrap{
            border-radius: 50%;
            @media screen and (max-width: $break-ipad) {
                text-align: center;
            }
            img{
              height: 200px;
              border-radius: 50%;
              width: 200px;
              margin: 10px auto;
              object-fit: cover;
              object-position: top;
            }
        }
       .ProfileInfo{
        @media screen and (max-width: $break-ipad) {
            text-align: center;
        }
           a{
            background-color: #78af4d;
            color: #fff;
            font-size: 20px;
            border-color: #78af4d;
            border-radius: 6px;
            font-size: 16px;
            margin-top: 10px;
            display: inline-block;
            padding: 5px 15px;
            &:hover{
                text-decoration: none;
            }
            &:nth-child(1){
                margin-right: 10px;
            }
           }
       }
      
    }
    .InfoGrid{
        box-shadow: 0px 3px 5px 3px #ececec;
        padding: 20px;
        margin-top: 30px;
        border-radius: 20px;
        h4 {
            font-size: 20px;
            font-weight: 500;
            position: relative;
            width: max-content;
            padding: 10px 0px;
            margin-top: 20px;
            &.LineBottom {
              border-bottom: 1px solid $dargreen;
            }
            &.LineRight {
              &:after {
                content: "";
                width: 30px;
                height: 2px;
                background-color: $dargreen;
                position: absolute;
                right: -40px;
                top: 25px;
              }
            }
          }
          table{
              width: 100%;
              tr{
                  td{
                      font-size: 16px;
                  }
              }
          }
          ul{
              padding: 0px;
              list-style-type: none;
              font-size: 16px;
              span{
                  margin-left: 10px;
              }
          }
       }
    .TabsWrap{
        ul{
            &.nav {
                border: none;
                margin-bottom: 20px;
                li{
                    a{
                        color: #959dab;
                        font-size: 16px;
                        position: relative;
                        border: none;
                        &.active{
                            background-color: transparent;
                            border: none;
                            color: $dargreen;
                            &:after{
                                content: '';
                                position: absolute;
                                bottom: 0px;
                                background-color: $dargreen;
                                width: 75%;
                                height: 2px;
                                left: 0;
                                right: 0;
                                margin: 0 auto;
                            }
                        }
                        &:hover{
                            border:none;
                            text-decoration: none;
                        }
                        @media screen and (max-width: $break-ipad) {
                          padding: 0.5rem 0.5rem;
                          font-size: 14px;
                        }
                    }
                }
            }
        }
        .tab-content{
            .tab-pane {
                .row{
                    .col-md-4{
                      
                        a{
                            &:hover{
                                text-decoration: none;
                            }
                            .ImageBox{
                                position: relative;
                                margin-bottom: 30px;
                               
                                .ImageArea{
                                    // background-color: #ddd;
                                    max-height: 280px;
                                    overflow: hidden;
                                    border-radius: 20px;
                                    img{
                                        max-width: 100%;
                                        max-height: 230px;
                                        @media screen and (max-width: $break-laptoplg1440) {
                                            max-height: 200px;
                                        }
                                        @media screen and (max-width: $break-ipad) {
                                            max-height: none;
                                        }
                                    }
                                    text-align: center;
                                }
                                .TextOverlay{
                                    position: relative;
                                   top: -30px;
                                    background-color: #fff;
                                    padding: 10px;
                                    left: 0;
                                    right: 0;
                                    width: 80%;
                                    margin: 0 auto;
                                    border-radius: 20px;
                                    text-align: center;
                                    min-height: 95px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    box-shadow: 0px 0px 20px 0px #ddd;
                                    color: #7D8597;
                                    font-size: 16px;
                                    @media screen and (max-width: $break-belowlaptop) {
                                        position: relative;
                                        bottom: unset;
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
                .SubscriptionCard{
                  box-shadow: 0px 3px 5px 3px #ececec;
                  padding:  15px;
                  margin-top: 10px;
                  border-radius: 20px;
                  width: 500px;
                  max-width: 100%;
               
                
               
                  .BoxWrap{
                     .col-md-12 {
                       display: flex;
                       align-items: center;
                       margin: 2px 0;
                       label {
                         min-width: 180px;
                         font-size: 1rem;
                       }
                       
                       h5 {
                         font-size: 1rem;
                       }
                       .membership-plan {
                         color: $dargreen;
                        }
                      }
                      .renewLabel{
                        font-size: 1rem;
                       }
                  }
                  
                }
                .TransactionCard{
                  box-shadow: 0px 3px 5px 3px #ececec;
                  padding:  20px;
                  margin-top: 30px;
                  border-radius: 20px;
                  width: 90%;
                  margin: 0 auto;
                  max-width: 100%;
                  display: flex;
                  align-items: center;
                  min-height: 400px;
                  justify-content: center;
                  .BoxWrap{
                    text-align: center;
                    width: 100%;
                    h2{
                      color: $dargreen;
                    }
                    table{
                      width: 100%;
                      margin: 0 auto;
                      tr{
                        td{
                         width: 50%;
                         padding:5px 10px;
                         vertical-align: top;
                         &:nth-child(1){
                           text-align: right;
                         }
                         &:nth-child(2){
                           text-align: left;
                         }
                          span{
                            font-weight: 600;
                          }
                        }
                      }
                    }
                    .ButtonWrap{
                      margin-top: 20px;
                      text-align: center;
                      button{
                          background-color: #78af4d;
                          color: $white;
                          font-size: 16px;
                          border-color: $dargreen;
                          width: 250px;
                          height: 45px;
                          border-radius: 6px;
                          margin-bottom: 20px;
                          
                          &:nth-child(1){
                              margin-right: 10px;
                          }
                          &:focus{
                            outline: none;
                            box-shadow: none;
                            border:none;
                          }
                      }
                  }
                  }
                 
                }
                .LendingOrderCard{
                  box-shadow: 0px 3px 5px 3px #ececec;
                  padding:  20px;
                  margin-top: 30px;
                  border-radius: 20px;
                  width: 90%;
                  margin: 0 auto;
                  max-width: 100%;
                  display: flex;
                  align-items: center;
                  min-height: 400px;
                  justify-content: center;
                  .BoxWrap{
                    text-align: center;
                    width: 100%;
                    h2{
                      color: $dargreen;
                    }
                    table{
                      width: 100%;
                      margin: 0 auto;
                      tr{
                        td{
                         width: 50%;
                         padding:5px 10px;
                         vertical-align: top;
                         &:nth-child(1){
                           text-align: right;
                         }
                         &:nth-child(2){
                           text-align: left;
                         }
                          span{
                            font-weight: 600;
                          }
                        }
                      }
                    }
                    .ButtonWrap{
                      margin-top: 20px;
                      text-align: center;
                      button{
                          background-color: #78af4d;
                          color: $white;
                          font-size: 16px;
                          border-color: $dargreen;
                          width: 250px;
                          height: 45px;
                          border-radius: 6px;
                          margin-bottom: 20px;
                          
                          &:nth-child(1){
                              margin-right: 10px;
                          }
                          &:focus{
                            outline: none;
                            box-shadow: none;
                            border:none;
                          }
                      }
                  }
                  }
                 
                }
            }
        }
    }
}
.EditProfile {
    border: none;
    h4 {
        font-size: 20px;
        font-weight: 500;
        position: relative;
        width: max-content;
        padding: 10px 0px;
        margin-top: 20px;
        &.LineRight {
          &:after {
            content: "";
            width: 30px;
            height: 2px;
            background-color: $dargreen;
            position: absolute;
            right: -40px;
            top: 25px;
          }
        }
      }
      .FormWrap {
        form {
            box-shadow: 0px 3px 5px 3px #ececec;
            padding: 20px 30px;
            margin-top: 30px;
            border-radius: 10px;
            position: relative;
            @media screen and (max-width: $break-mobile) {
              padding: 20px;
            }
            .row {
                div {
                  label {
                    color: #3c4b64;
                    display: block;
                    margin: 10px 0px;
                    font-size: 16px;
                  }
                  input,
                  select {
                    border-color: #707070;
                    height: 60px;
                    border-radius: 6px;
                    box-shadow: 0px 3px 5px 3px #ececec;
                    background-color: #fff;
                    width: 100%;
                    border: none;
                    padding: 10px;
                    @include placeholder {
                      color: #aaaaaa;
                    }
                    &:focus {
                      outline: none;
                      border: none;
                    }
                  }
                  select {
                    -webkit-appearance: none;
                    position: relative;
                    z-index: 9;
                    background-color: transparent;
                  }
                  .SelectWrap {
                    position: relative;
                    &:after {
                      content: "";
                      width: 20px;
                      height: 10px;
                      background-image: url("../assets/down-filled-triangular-arrow.png");
                      background-size: contain;
                      right: 20px;
                      top: 60px;
                      position: absolute;
                      background-repeat: no-repeat;
                    }
                  }
                  .register-multi-check-box div{
                    min-width: 200px;
                    word-break: break-word;
                    @media screen and (max-width: $break-mobile) {
                      width: 100%;
                    }
                }
                  .checkWrapStep {
                    font-size: 16px;
                    .custom-control {
                      input[type="checkbox"] {
                        height: 15px;
                        width: 15px;
                        margin-right: 5px;
                        border: 1px solid #78af4d;
                        box-shadow: none;
                      }
                    }
                    &.BottomCheck {
                      position: relative;
                      &:before{
                        content: '';
                        width: 125px;
                        height: 125px;
                        background-image: url('../assets/patcharch.png');
                        right: 20%;
                        top: 10%;
                        position: absolute;
                      }
                      label {
                        color: #3c4b64;
                        display: block;
                        margin: 10px 0px;
                        font-size: 16px;
                      }
                      span {
                        color: #3c4b64;
                      }
                    }
                    label {
                      margin: 0px;
                      margin-left: 5px;
                      color: #aaaaaa;
                      a {
                        color: #78af4d;
                        &:hover {
                          text-decoration: none;
                        }
                      }
                      &:before {
                        top: 5px;
                      }
                      &:after {
                        top: 5px;
                      }
                    }
        
                    .custom-control-input {
                      &:checked ~ .form-check-label {
                        &:before {
                          background-color: #78af4d;
                          border-color: #78af4d;
                        }
                      }
                      &:focus {
                        &:not(:checked) ~ .form-check-label {
                          &:before {
                            border-color: #78af4d;
                          }
                        }
                      }
                      &:focus ~ .form-check-label {
                        &:before {
                          box-shadow: none;
                        }
                      }
                    }
                    &.ProfileCheck{
                      input{
                        height: 20px;
                        width: 20px;
                        box-shadow: none;
                      }
                      label{
                        margin-top: 3px;
                        margin-bottom: 3px;
                        margin-left: 10px;
                        cursor: none;
                      }
                    }
                  }
                }
              }
              .ButtonWrap{
                margin-top: 20px;
                text-align: center;
                button{
                    background-color: #78af4d;
                    color: $white;
                    font-size: 16px;
                    border-color: $dargreen;
                    width: 150px;
                    height: 45px;
                    border-radius: 6px;
                    margin-bottom: 20px;
                    
                    &:nth-child(1){
                        margin-right: 10px;
                    }
                    
                }
            }
        }
      }
    
}
.successStatus{
  color: #2eb85c;
} 
.failStatus{
 color: red;
} 
.ChangePass{
  border: none;
  h4 {
      font-size: 20px;
      font-weight: 500;
      position: relative;
      width: max-content;
      padding: 10px 0px;
      margin-top: 20px;
      &.LineRight {
        &:after {
          content: "";
          width: 30px;
          height: 2px;
          background-color: $dargreen;
          position: absolute;
          right: -40px;
          top: 25px;
        }
      }
    }
    .FormWrap {
      form {
          box-shadow: 0px 3px 5px 3px #ececec;
          padding: 20px 30px;
          margin-top: 30px;
          border-radius: 10px;
          position: relative;
          @media screen and (max-width: $break-mobile) {
            padding: 20px;
          }
         
                label {
                  color: #3c4b64;
                  display: block;
                  margin: 10px 0px;
                  font-size: 16px;
                }
                input,
                select {
                  border-color: #707070;
                  height: 60px;
                  border-radius: 6px;
                  box-shadow: 0px 3px 5px 3px #ececec;
                  background-color: #fff;
                  width: 100%;
                  border: none;
                  padding: 10px;
                  @include placeholder {
                    color: #aaaaaa;
                  }
                  &:focus {
                    outline: none;
                    border: none;
                  }
                }
                select {
                  -webkit-appearance: none;
                  position: relative;
                  z-index: 9;
                  background-color: transparent;
                }
                .SelectWrap {
                  position: relative;
                  &:after {
                    content: "";
                    width: 20px;
                    height: 10px;
                    background-image: url("../assets/down-filled-triangular-arrow.png");
                    background-size: contain;
                    right: 20px;
                    top: 60px;
                    position: absolute;
                    background-repeat: no-repeat;
                  }
                }
                .checkWrapStep {
                  font-size: 16px;
          
                  label {
                    cursor: pointer;
                    a {
                      color: #78af4d;
                      &:hover {
                        text-decoration: none;
                      }
                    }
                    &:before {
                      top: 5px;
                    }
                    &:after {
                      top: 5px;
                    }
                  }
          
                  .custom-control-input {
                    &:checked ~ .custom-control-label {
                      &:before {
                        background-color: #78af4d;
                        border-color: #78af4d;
                      }
                    }
                    &:focus {
                      &:not(:checked) ~ .custom-control-label {
                        &:before {
                          border-color: #78af4d;
                        }
                      }
                    }
                    &:focus ~ .custom-control-label {
                      &:before {
                        box-shadow: none;
                      }
                    }
                  }
                }
             
            .ButtonWrap{
              margin-top: 20px;
              text-align: center;
              @media screen and (max-width: $break-mobile) {
                display: flex !important;
              }

              button{
                  background-color: #78af4d;
                  color: $white;
                  font-size: 16px;
                  border-color: $dargreen;
                  width: 150px;
                  height: 45px;
                  border-radius: 6px;
                  margin-bottom: 20px;
                  
                  &:nth-child(1){
                      margin-right: 10px;
                  }
                  &:focus{
                    background-color: #78af4d;
                    outline: none;
                    box-shadow: none;
                    border: none;
                  }
              }
          }
      }
    }
}
.transication-table {
  overflow-x: auto;
  table {
    min-width: 1340px;
    border: 1px solid #f2f2f2;
    margin-bottom: 0;
    th, td {
      font-size: 16px;
      vertical-align: middle;
      text-align: center;
    }
    th:last-child {
      min-width: 200px;
    }
  }
  .price {
    color:  $dargreen;
    font-weight: 600;
  }
  .ButtonWrap { 
 
    button{
      background-color: #78af4d;
      color: $white;
      font-size: 16px;
      border-color: $dargreen;
      border-radius: 6px;
    }
  }
}
.profile-pagination {
  margin-top: 20px;
  .position-relative {
    overflow-x: auto; 
      table {
        min-width: 1340px;
        border: 1px solid #f2f2f2 !important;
        margin-bottom: 0;
        th, td {
          @media (min-width:1366px) {
            font-size: 16px;
          }
        }
      };
    }
  
  .pagination {
    margin-top: 20px;
    justify-content: flex-end !important;
    .page-item {
      margin: 0 4px;
      a {
        border-color: $dargreen;
        border-radius: 4px;
        color: $dargreen;
        padding: 4px 9px;
      }
      &.disabled {
        opacity: 0.6;
      }
      &.active {
        a {
          background-color: $dargreen;
          color: #fff;
        }
      }
    }

  }
}
.custom-control.custom-checkbox {
  display: flex;
  align-items: center;
 label {
   margin-bottom: 0 !important;
   cursor: default !important;
   color: #3c4b64 !important;
   margin-left: 5px !important;
   padding-right: 10px !important;
 }
}

@media screen and (max-width:$break-mobile){
  .edit_Profile_Tabs_CSS {
    .nav{
      margin-left: -17px;
      width: 106%;
    }
  }
}