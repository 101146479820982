.lendingDescription {
  padding-right: 25%;
 
  @media screen and (max-width: $break-laptoplg1440) {
    padding-right: 30%;
  }
  @media screen and (max-width: $break-belowlaptop) {
    padding-right: 0%;
  }
  &:before {
    content: "";
    width: 100px;
    background-color: #adcf93;
    height: calc(100vh + 900px);
    top: -20px;
    bottom: 0px;
    position: absolute;
    right: 70px;
    @media screen and (max-width: $break-belowlaptop) {
      display: none;
    }
  }
  &:after {
    content: "";
    position: absolute;
    right: 00px;
    height: 250px;
    width: 250px;
    background-image: url("../assets/imagerounded.png");
    background-size: cover;
    border-radius: 50%;
    // border: 2px solid #adcf93;
    top: 28%;
    @media screen and (max-width: $break-belowlaptop) {
      display: none;
    }
  }
  p {
    font-size: 16px;
  }
  h4 {
    font-size: 18px;
  }
  ul {
    padding: 0px;
    position: relative;
    &:after{
      content: '';
      position: absolute;
      height: 300px;
      width: 300px;
      top: -120px;
      right: 0px;
      left: 0;
      margin: 0 auto;
      background-image: url('../assets/stethologin.png');
      
  }
    li {
      list-style-type: none;
      position: relative;
      font-size: 16px;
      margin-bottom: 10px;
      padding-left: 25px;
      &:before {
        content: "";
        height: 20px;
        width: 20px;
        // background-color: $dargreen;
        position: absolute;
        left: 0;
        top: 4px;
        border-radius: 50%;
        background-image: url("../assets/checkgreen.png");
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    span {
      display: none;
    }
  }
}
.FormWrap {
  form {
    box-shadow: 0px 3px 5px 3px #ececec;
    padding: 20px 30px;
    margin-top: 30px;
    border-radius: 10px;
    position: relative;
    z-index: 9;
    background: #fff;
    @media screen and (max-width: $break-mobile) {
      padding: 20px;
    }
    &.LeandingForm{
      &:after{
        content: '';
        height: 170px;
        width: 170px;
        position: absolute;
        bottom: -120px;
        background-image: url('../assets/polyleft.png');
        background-size: contain;
        background-repeat: no-repeat;
        left: -10px;
        z-index: 1 !important;
      }
    }
    .HeadBtn {
      position: relative;
      &:before{
        position: absolute;
        content: '';
        height: 50px;
        width: 50px;
        background-image: url('../assets/circlepatch.png');
        top: 70px;
        left: 200px;
        background-size: contain;
        background-repeat: no-repeat;
        z-index: -1;
    }
    &:after{
      position: absolute;
      content: '';
      height: 50px;
      width: 50px;
      background-image: url('../assets/polyleftpatch.png');
      top: 70px;
      right: 200px;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: -1;
  }
      .BtnWrapEdit {
        position: absolute;
        top: 10px;
        right: 0px;
        @media screen and (max-width: $break-mobilesmall) {
          position: relative;
          top: unset;
          right: unset;
          margin-bottom: 10px;
         
        }
        
        button {
          border: 1px solid #3c4b64;
          border-radius: 20px;
          padding: 5px 20px;
          font-size: 14px;
          img {
            width: 15px;
            margin-right: 5px;
          }
          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }
     
    }

    .StepWrap {
      position: relative;

      h4 {
        font-size: 20px;
        font-weight: 500;
        position: relative;
        width: max-content;
        padding: 10px 0px;
        margin-top: 20px;
        &.LineBottom {
          border-bottom: 1px solid $dargreen;
        }
        &.LineRight {
          &:after {
            content: "";
            width: 30px;
            height: 2px;
            background-color: $dargreen;
            position: absolute;
            right: -40px;
            top: 25px;
          }
        }
      }
      .row {
        div {
          label {
            color: #3c4b64;
            display: block;
            margin: 10px 0px;
            font-size: 16px;
          }
          input,
          select {
            border-color: #707070;
            height: 60px;
            border-radius: 6px;
            box-shadow: 0px 3px 5px 3px #ececec;
            background-color: #fff;
            width: 100%;
            border: none;
            padding: 10px;
            @include placeholder {
              color: #aaaaaa;
            }
            &:focus {
              outline: none;
              border: none;
            }
          }
          select {
            -webkit-appearance: none;
            position: relative;
          }
          .SelectWrap {
            position: relative;
            &:after {
              content: "";
              width: 20px;
              height: 10px;
              background-image: url("../assets/down-filled-triangular-arrow.png");
              background-size: contain;
              right: 20px;
              top: 60px;
              position: absolute;
              background-repeat: no-repeat;
            }
          }
        }
      }
    }
    .btn-component {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: $break-mobile580) {
        display: block;
      }
      button {
        background-color: #78af4d;
        color: $white;
        font-size: 20px;
        border-color: $dargreen;
        width: 200px;
        height: 60px;
        border-radius: 6px;
        margin-bottom: 20px;

        @media screen and (max-width: $break-ipad) {
          margin-top: 30px;
          display: block;
          height: 40px;
          width: 150px;
          max-width: 100%;
          font-size: 16px;
        }
        @media screen and (max-width: $break-mobile580) {
          margin: 10px auto;
        }
        &:focus {
          box-shadow: none;
          border: none;
        }
      }
      .checkWrapStep {
        font-size: 16px;

        label {
          cursor: pointer;
          a {
            color: #78af4d;
            &:hover {
              text-decoration: none;
            }
          }
          &:before {
            top: 5px;
          }
          &:after {
            top: 5px;
          }
        }

        .custom-control-input {
          &:checked ~ .custom-control-label {
            &:before {
              background-color: #78af4d;
              border-color: #78af4d;
            }
          }
          &:focus {
            &:not(:checked) ~ .custom-control-label {
              &:before {
                border-color: #78af4d;
              }
            }
          }
          &:focus ~ .custom-control-label {
            &:before {
              box-shadow: none;
            }
          }
        }
        &.checkoutcheckbox{
          padding-left: 0px;
          align-items: flex-start;
          .custom-control{
            align-items: flex-start;
            margin-right: 0px;
            padding-right: 0px;
            max-width: 20px;
            input{
              margin-right: 0px;
            }
          }
          a{
            color: #78af4d;
            font-size: 16px;
    min-width: 190px;
          }
          p{
            margin-bottom: 0px;
          }
        }
      }
      &.buttonStepLast{
        display: block;
        button{
          margin-bottom: 0px;
          margin-top: 12px;
          margin-left: 0;
          margin-right: 0;
          z-index: 2;
          &.no-wrap {
            white-space: nowrap;
          }
        }
        .btn-container {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
        }
      }
    }
    .BoxWrap{
      .col-md-12 {
        display: flex;
        align-items: center;
        margin: 2px 0;
        label {
          min-width: 180px;
          font-size: 1rem;
        }
        h5 {
          font-size: 1rem;
        }
      }
    }
    .shippingBox {
      .shippigInfo {
        padding: 0px;
        li {
          list-style-type: none;
          font-size: 16px;
          color: #3c4b64;

          &.emailbox {
            margin-top: 10px;
          }
        }
        
      }
      .CustomShipping {
        padding: 0px;
        li {
          list-style-type: none;
          font-size: 14px;
          color: #3c4b64;
          .custom-radio {
            width: 100%;
            padding-left: 0px;
            margin-bottom: 10px;

            label {
              width: 100%;
              position: relative;
              border: 1px solid #3c4b64;
              border-radius: 20px;
              padding: 5px;
              padding-left: 30px;
              cursor: pointer;
              margin: unset;
              font-size: 14px;
              padding-right: 60px;
              span {
                position: absolute;
                top: 5px;
                right: 15px;
              }
              &:before,
              &:after {
                left: 8px;
                top: 8px;
              }
              &:before {
                background-color: $white;
                border-color: $dargreen;
              }
            }
          }
          .custom-radio
            .custom-control-input:checked
            ~ .custom-control-label::after {
            background-image: none;
            background-color: #78af4d;
            border-radius: 50%;
            height: 10px;
            width: 10px;
            left: 11px;
            top: 11px;
          }
          .custom-control-input:checked ~ .custom-control-label::before {
            box-shadow: none;
          }
        }
      }
      .shippingMethod{
        position: relative;
        &:before{
          position: absolute;
          content: '';
          height: 80px;
          width: 80px;
          background-image: url('../assets/inclinetrilite.png');
          top: 90px;
          right: -500%;
          background-size: contain;
          background-repeat: no-repeat;
          z-index: -1;
      }
      &:after{
        position: absolute;
        content: '';
        height: 50px;
        width: 50px;
        background-image: url('../assets/polyleftpatch.png');
        top: 0px;
        left: 290%;
        background-size: contain;
        background-repeat: no-repeat;
        z-index: -1;
    }
    @media only screen and (max-width: 1280px) {
      &:before{
        content: none;
      }
      &:after{
        content: none;
      }
    }
    @media only screen and (max-width: $break-mobile) {
      &:before{
        content: none;
      }
      &:after{
        content: none;
      }
    }
 
      }
      .step3Shipping{
        position: relative;
        &:before{
          position: absolute;
          content: '';
          height: 80px;
          width: 80px;
          background-image: url('../assets/inclinetrilite.png');
          top: 90px;
          right: -500%;
          background-size: contain;
          background-repeat: no-repeat;
          z-index: -1;
      }
      &:after{
        position: absolute;
        content: '';
        height: 50px;
        width: 50px;
        background-image: url('../assets/polyleftpatch.png');
        top: 0px;
        left: 290%;
        background-size: contain;
        background-repeat: no-repeat;
        z-index: -1;
    }
    @media screen and (max-width: $break-mobile) {
      &:before{
        content: none;
      }
      &:after{
        content: none;
      }
    }
      }
    }
    .BackPatch{
      &:before{
          position: absolute;
          content: '';
          height: 70px;
          width: 70px;
          background-image: url('../assets/circlepatch.png');
          top: 0;
          left: 120px;
          background-size: contain;
          background-repeat: no-repeat;
          z-index: -1;
      }
     
  }
  .patchtop{
      &:before{
          position: absolute;
          content: '';
          height: 70px;
          width: 70px;
          background-image: url('../assets/polytoppatch.png');
          top: -100px;
          right: 60px;
          background-size: contain;
          background-repeat: no-repeat;
          z-index: -1;
      }
  }
  .rightpatch{
      &:before{
          position: absolute;
          content: '';
          height: 50px;
          width: 50px;
          background-image: url('../assets/polyleftpatch.png');
          top: 20px;
          right: 50%;
          background-size: contain;
          background-repeat: no-repeat;
          z-index: -1;
      }
      &:after{
          position: absolute;
          content: '';
          height: 70px;
          width: 70px;
          background-image: url('../assets/inclinetrilite.png');
          bottom: 10px;
          right: 20%;
          background-size: contain;
          background-repeat: no-repeat;
          z-index: -1;
      }
  }
  .bottomPatchTri{
    &:before{
      position: absolute;
      content: '';
      height: 50px;
      width: 50px;
      background-image: url('../assets/inclinetrilite.png');
      bottom: -20px;
      right: 50%;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: -1;
  }
  }
  }
}

.OrderSummary {
  box-shadow: 0px 3px 5px 3px #ececec;
  padding: 20px 30px;
  margin-top: 30px;
  border-radius: 10px;
  position: relative;
  &:before{
    content: '';
    height: 200px;
    width: 200px;
    background-image: url('../assets/logincardiology.png');
    position: absolute;
    right: 0;
    top: 0;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .row{
    position: relative;
    &:before{
      position: absolute;
      content: '';
      height: 70px;
      width: 70px;
      background-image: url('../assets/polyleftpatch.png');
      top: 60px;
      right: 170px;
      background-size: contain;
      background-repeat: no-repeat;
     
    }
    &:after{
      position: absolute;
      content: '';
      height: 70px;
      width: 70px;
      background-image: url('../assets/inclinetrilite.png');
      bottom: 30px;
      left: 45%;
      background-size: contain;
      background-repeat: no-repeat;
     
    }
  }
  h4 {
    text-align: center;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 30px;
    &:after {
      content: "";
      height: 1px;
      width: 150px;
      bottom: 0px;
      background-color: $dargreen;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
  .ImageWrap {
    text-align: center;
    margin-bottom: 20px;
    img {
      max-width: 100%;
    }
  }

  table {
    width: 100%;
    tbody {
      tr {
        border-bottom: 1px solid #ddd;
        td {
          font-size: 16px;
          padding: 10px 0px;
        }
        &:last-child {
          border-bottom: none;
          td {
            color: $dargreen;
          }
        }
      }
    }
  }
}
.LendingBlock {
  .col-md-4{
    @media screen and (max-width: $break-belowlaptop) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @media screen and (max-width: $break-mobile580) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .btnWrapLendingLearn{
    > a{
      background-color: #78af4d;
color: #fff;
padding: 10px 25px;
border-radius: 6px;
font-size: 16px;
box-shadow: none;
border: none;
font-weight: 400;
display: block;
width: 200px;
max-width: 100%;
margin: 0 auto;
    }
  }
}
.modal{
  &.showDialog{
    opacity: 1;
    display: flex;
    background-color: #000000c4;
    justify-content: center;
    align-items: center;
    overflow: hidden !important;
    &.ModalCustom{
     
      .modal-dialog{
        max-width: 1440px;
        .submit-btn-billing-info{
          background-color: #78af4d;
          color:#fff;
          margin-right: 1rem;
        }
        @media only screen and (max-width: 990px){
          max-width: 95%;
          .card-body-update-payment{
            padding: 0.1rem;
          }
         }
       
        top: 60px;
        .modal-body{
          max-height: 400px;
          overflow-y: auto;
          form{
           
              .form-group{
                > label{
                  display: none;
                }
              }
           
          }
        }
      }
    }
  }
}

.ShippingAddressBlock{
 position: relative;
 padding-top: 30px;
 .innerblock{
   border: 1px solid #78af4d;
   padding: 10px;
    border-radius: 6px;
    word-break: break-all;
    min-height: 170px;
   .BtnWrapEdit{
    // position: absolute;
    // top: 0;
    // right: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin:5px 0px;
    button{
     background-color: #fff;
     color: #78af4d;
     font-size: 13px;
     border-radius: 6px;
     font-weight: 600;
     padding: 5px 10px;
      img{
        width: 12px;
      }
      &:hover, &:focus{
        outline: none;
        box-shadow: none;
      }
      &.delete{
        color: red;
      }
    }
  }
  .custom-control {
    cursor: pointer;
    .custom-control-label{
      &:before{
        top: 4px;
      }
      &:after{
        top: 4px;
      }
      label{
        color: #000;
      }
      @media screen and (max-width: $break-mobilesmall) {
       font-size: 13px;
      }
    }
  }
  
  .custom-control-input:checked ~ .custom-control-label::before{
    border-color: #78af4d;
    background-color: #78af4d;
  }
 }

}
.HeadingHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
 
  h4{
    margin:0px !important;
  }
  button{
    background-color: #78af4d;
    color: #fff;
    font-size: 14px;
    border-color: #78af4d;
    border-radius: 6px;
    @media screen and (max-width: $break-mobilesmall) {
      margin-top: 10px;
    }
    &:hover, &:focus{
      color: #fff;
      outline: none;
      box-shadow: none;
    }
  }
}
.AddadressModal{
  .modal-body {
    max-height: 500px;
    overflow: auto;
    @media only screen and (min-width: 768px) and (max-width: 990px){
           max-height: fit-content !important;
     }
    .footer-btn {
      display: flex;
      justify-content: flex-end;
      grid-gap: 10px;
      .close {
        background-color: #fff;
        color: #000;
        font-size: 14px;
        border-color: #000;
        border-radius: 6px;
        border: 1px solid;
        padding: 0.375rem 0.75rem;
        &:hover, &:focus{
          color: #000;
          outline: none !important;
          box-shadow: none !important;
          background-color: #fff !important;
        }
      }
      .Submission {
        background-color: #78af4d;
        color: #fff;
        font-size: 14px;
        border-color: #78af4d;
        border-radius: 6px;
        &:hover, &:focus{
          color: #78af4d;
          outline: none;
          box-shadow: none;
          background-color: #ffff !important;
        }
      }
    }
  }
  input{
    color: #000;
    &:focus{
      color: #000;
    }
  }
}
.lending-items-detail{
  h1, h3{
    width: 80%;
  }
}

.my-lending-lib-table-main-div{ overflow: auto; 
  @media screen and (max-width: $break-mobile){
      padding: 0 0;
      margin: 0 15px;
  }
}

@media screen and (max-width: 980px){
    .lending-lorder-table-first-div{ min-width: 150px; }
    .lending-lorder-table-last-div{ min-width: 200px; }
}

// CSS for only Safari Browser 
@media not all and (min-resolution:.001dpcm)
    { @supports (-webkit-appearance:none) and (stroke-color:transparent) {
      .AddadressModal .modal-body .footer-btn button{
       margin-left: 10px; 
      }
}}

.ModalOpen .main-dashboard-rightside-content {
  overflow:visible !important;
}

// CSS for event calendor Popup
.ModalCustom.popupSize{
  .modal-dialog{
    width: 100%;
    max-width: 800px !important;
    @media screen and (max-width: 980px){
      width: 70%;
    }
  }
  .card{
    border: none !important;
  }
  .widthSecurity{
    .form-group{
      width: calc(100% - 60px);
    }
  }
  
}