.RegistrationWrap {
  .HeadingWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    z-index: 9;
    position: relative;

    h1 {
      a {
        color: #3c4b64;
        &:hover {
          text-decoration: none;
        }
      }
      @media screen and (max-width: $break-laptoplg1440) {
        font-size: 30px;
      }
      @media screen and (max-width: $break-ipad) {
        font-size: 25px;
      }
    }
    @media screen and (max-width: $break-mobilesmall) {
      display: block;
    }
    .BreadCRumb {
      p {
        margin-bottom: 0px;
        font-size: 16px;
        i {
          font-size: 12px;
          margin: 0px 5px;
        }
        a {
          color: #000;
        }
        &.FilterDropdown {
          .form-group {
            display: flex;
            align-items: center;
            justify-content: space-between;
            label {
              margin-bottom: 0px;
              margin-right: 10px;
              min-width: 75px;
              font-weight: 500;
            }
            select {
              border-radius: 100px;
              border-color: #3c4b64;
              max-width: 140px;
              &:focus {
                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }
  .FormWrap {
    border: none;
    form {
      box-shadow: 0px 3px 5px 3px #ececec;
      padding: 20px 30px;
      margin-top: 0px;
      border-radius: 10px;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        bottom: -70px;
        background-image: url("../assets/cardiology.png");
        width: 200px;
        height: 200px;
        background-position: 100%;
        background-repeat: no-repeat;
        right: -140px;
        z-index: -1;
        @media screen and (max-width: $break-ipad880) {
          right: 30px;
        }
      }
      &:before {
        content: "";
        position: absolute;
        bottom: -150px;
        height: 250px;
        width: 250px;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("../assets/polyleft.png");
        left: -40px;
      }
      @media screen and (max-width: $break-mobile) {
        padding: 20px;
      }
      div:nth-child(2), div:nth-child(3){
        .PatchTopRight{
          .form-group{
            .has-value {              
              .Select-control{
                height: 60px !important;

                .Select-input{
                  display: none!important;
                }
              }
            }
            .Select-menu-outer{
              z-index: 99;
            }
          .Select-control {
              border:none !important;
              border-radius: 6px !important;
              box-shadow: 0px 3px 5px 3px #ececec !important;

              .Select-placeholder{
                padding: 10px !important;
                z-index: 2 !important;
              }

              .Select-input {
                  height: 60px !important;
                  width: 100% !important;
                  input{
                      box-shadow: none !important;
                      margin-top: -12px !important;
                      z-index: 1;
                  }
              }
              .Select-value {
                  padding: 10px  !important;
              }    
          }
        }
      }
    }
      .row {
        .PatchTop {
          &:before {
            content: "";
            position: absolute;
            top: 20px;
            height: 50px;
            width: 50px;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url("../assets/circlepatch.png");
            left: 20%;
          }
          input {
            z-index: 9;
            background-color: #fff;
            position: relative;
          }
        }
        .PatchTopRight {
          &:before {
            content: "";
            position: absolute;
            top: 20px;
            height: 50px;
            width: 50px;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url("../assets/polyleftpatch.png");
            right: 20%;
          }
          input {
            z-index: 9;
            background-color: #fff;
            position: relative;
          }
        }
        .PatchTopInner {
          &:before {
            content: "";
            position: absolute;
            top: 30px;
            height: 50px;
            width: 50px;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url("../assets/polyleftpatch.png");
            right: 20%;
          }
          input {
            z-index: 9;
            background-color: #fff;
            position: relative;
          }
        }
        .PatchTopRightTri{
          &:before {
            content: "";
            position: absolute;
            top: -10px;
            height: 80px;
            width: 80px;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url("../assets/inclinetrilite.png");
            right: 40%;
          }
          input {
            z-index: 0;
            background-color: #fff;
            position: relative;
          }
        }
        div {
          .form-group {
            > label {
              display: none;
            }
          }
          label {
            color: #3c4b64;
            display: block;
            margin: 10px 0px;
            font-size: 16px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            svg {
              margin-right: 10px;
            }
          }
          input,
          select, .choose-file {
            border-color: #707070;
            height: 60px;
            border-radius: 6px;
            box-shadow: 0px 3px 5px 3px #ececec;
            background-color: #fff;
            width: 100%;
            border: none;
            padding: 10px;
            @include placeholder {
              color: #aaaaaa;
            }
            &:focus {
              outline: none;
              border: none;
            }
          }
          select {
            -webkit-appearance: none;
            position: relative;
            z-index: 9;
            background-color: transparent;
          }
          .SelectWrap {
            position: relative;
            &:after {
              content: "";
              width: 20px;
              height: 10px;
              background-image: url("../assets/down-filled-triangular-arrow.png");
              background-size: contain;
              right: 20px;
              top: 60px;
              position: absolute;
              background-repeat: no-repeat;
            }
            @media screen and (max-width: $break-mobile) {
              &::after {
                right: 10px;
              }
              select {
                padding-right: 35px;
              }
            }
            select {
              padding-right: 45px;
            }
          }
          .register-multi-check-box div{
            min-width: 200px;
                    word-break: break-word;
                    max-width: 200px;
                    align-items: flex-start;
                    @media screen and (max-width: $break-mobile) {
                      width: 100%;
                    }
          }

          .checkWrapStep {
            font-size: 16px;
            .custom-control {
              input[type="checkbox"] {
                height: 15px;
                width: 15px;
                margin-right: 5px;
                border: 1px solid #78af4d;
                box-shadow: none;
              }
            }
            &.BottomCheck {
              position: relative;
              &:before{
                content: '';
                width: 125px;
                height: 125px;
                background-image: url('../assets/patcharch.png');
                right: 20%;
                top: 10%;
                position: absolute;
              }
              label {
                color: #3c4b64;
                display: block;
                margin: 10px 0px;
                font-size: 16px;
              }
              span {
                color: #3c4b64;
              }
              &.CheckValid{
                .help-block{
                  margin-top: 5px;
                }
                label{
                  margin-top: 5px;
                }
              }
            }
            label {
              margin: 0px;
              margin-left: 5px;
              color: #6d6d6d;
              a {
                color: #78af4d;
                &:hover {
                  text-decoration: none;
                }
              }
              &:before {
                top: 5px;
              }
              &:after {
                top: 5px;
              }
            }

            .custom-control-input {
              &:checked ~ .custom-control-label {
                &:before {
                  background-color: #78af4d;
                  border-color: #78af4d;
                }
              }
              &:focus {
                &:not(:checked) ~ .custom-control-label {
                  &:before {
                    border-color: #78af4d;
                  }
                }
              }
              &:focus ~ .custom-control-label {
                &:before {
                  box-shadow: none;
                }
              }
            }
          }
        }
        .ProfileImage {
          .form-group {
         
            input {
              box-shadow: none;
              height: auto;
              padding: 18px 10px;
              width: 100% !important;
              cursor: pointer;
            }
          }
        }
      }
      .ButtonWrap {
        button {
          &.Submission {
            background-color: #78af4d;
            color: $white;
            font-size: 20px;
            border-color: $dargreen;
            width: 200px;
            height: 60px;
            border-radius: 6px;
            margin-bottom: 20px;
          }
          &.Back {
            background-color: $white;
            color: $dargreen;
            font-size: 20px;
            border-color: $dargreen;
            width: 200px;
            height: 60px;
            border-radius: 6px;
            margin-bottom: 20px;
          }
          &:focus {
            outline: none;
            box-shadow: none;
          }
          &:hover {
            text-decoration: none;
          }
          @media screen and (max-width: $break-ipad) {
            margin-top: 10px;
            display: block;
            height: 40px;
            width: 150px;
            max-width: 100%;
            font-size: 16px;
          }
        }
        .col-md-6 {
          &.text-right {
            @media screen and (max-width: $break-ipad) {
              text-align: left !important;
            }
          }
        }
      }
    }
  }
  .OrderBlock {
    .card-group {
      .card {
        border: none;
        padding: 0px;
        .card-body {
          .OrderSummary {
            box-shadow: 0px 3px 5px 3px #ececec;
            padding: 20px 30px;
            margin-top: 30px;
            border-radius: 10px;
            h4 {
              text-align: center;
              position: relative;
              padding-bottom: 15px;
              margin-bottom: 30px;
              &:after {
                content: "";
                height: 1px;
                width: 150px;
                bottom: 0px;
                background-color: $dargreen;
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
              }
            }
            .ImageWrap {
              text-align: center;
              margin-bottom: 20px;
              img {
                max-width: 100%;
              }
            }

            table {
              width: 100%;
              tbody {
                tr {
                  border-bottom: 1px solid #ddd;
                  td {
                    font-size: 16px;
                    padding: 10px 0px;
                    span {
                      color: $dargreen;
                    }
                    button {
                      background-color: $dargreen;
                      color: $white;
                      border-radius: 100px;
                      padding: 5px 30px;
                      font-size: 14px;
                      border: none;
                      line-height: normal;
                      &:focus {
                        outline: none;
                        box-shadow: none;
                      }
                    }
                  }
                  &:last-child {
                    border-bottom: none;
                    td {
                      color: $dargreen;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.modal-footer{
  button[type='button']{
    background-color: #fff;
    color: #000;
    font-size: 14px;
    border-color: #000;
    border-radius: 6px;
    &:focus, &:hover{
      color: #000;
  outline: none !important;
  box-shadow: none !important;
  background-color: #fff !important;
    }
  }
}
    

#termsandconditionCheckBox{
  .form-check-label {
    padding-right: 0 !important;
  }
}
@media screen and (max-width:$break-mobile){
  .register_page-checkBox_CSS input,  .check_box_css_for_mobile input {
    position: absolute;
    top: 3px;
  }
}

@media screen and (max-width:357px){
  .RegistrationWrap .FormWrap form .row div .checkWrapStep.BottomCheck label {
    font-size: 15px;
}
}

@media screen and (max-width:1120px){
.LayoutWrap .card .card-body .Subheading h3{
  margin: 0;
}
.LayoutWrap .card .card-body .Subheading h6{
  margin: 0;
}
}