.FootWrap{
    background-color: #334565;
    margin: 0px;
    position: relative;
    z-index: 9;
    &:before{
        content: '';
        position: absolute;
        right: 20px;
        height: 200px;
        width: 200px;
        background-image: url('../assets/stethoscope.png');
        background-position: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        bottom: -90px;
        z-index: 9;
        @media screen and (max-width: $break-ipadprolandscape) {
            display: none;
        }
    }
    .card-body{
        width: 1200px;
        margin: 0 auto;
        max-width: 100%;
        padding: 3.25rem 1.25rem;
        .FootHead{
            h3{
                color: $white;
                font-size: 18px;
                font-weight: 400;
                margin-bottom: 20px;
                span{
                    font-weight: bold;
                }
            }
        }
        .row{
            .col-md-3{
                h3{
                    font-size: 23px;
                    color: $white;
                    margin-bottom: 20px;
                    margin-top: 10px;
                }
                ul{
                    padding: 0px;
                    li{
                        list-style-type: none;
                        a{
                            color: #7d8597;
                            font-size: 16px;
                            font-weight: 700;
                        }
                        
                    }
                }
            }
        }
    }
}
.FootBottom {
    background-color: #f1f7ed;
    position: relative;
    z-index: 0;
    &:before{
        content: '';
        position: absolute;
        left: 20px;
        height: 200px;
        width: 200px;
        background-image: url('../assets/cardiologysmall.png');
        background-position: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        top: 110px;
        @media screen and (max-width: $break-abovelaptop) {
            left: 0px;
        }
        @media screen and (max-width: $break-ipadprolandscape) {
            display: none;
        }
    }
    &:after{
        content: '';
        position: absolute;
        right: 40px;
        height: 300px;
        width: 300px;
        background-image: url('../assets/footbottomImage.png');
        background-position: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        bottom: -50px;
        z-index: -1;
        @media screen and (max-width: $break-abovelaptop) {
            right: 0px;
        }
        @media screen and (max-width: $break-ipadprolandscape) {
            display: none;
        }
    }
    
    .FootBottomInner{
        display: flex;
        width: 1300px;
        max-width: 100%;
        padding: 50px 15px;
        margin:0 auto;
        border:none;
        flex-wrap: wrap;
        position: relative;   
        @media only screen and (min-width: $break-mobile)and (max-width: $break-ipadproportrait){
            padding: 50px 20px;
            padding-left: 90px;
            padding-right: 50px;
        }
        @media only screen and (max-width: $break-mobile){
            padding: 50px 40px;
        }
        @media screen and (min-width: $break-ipad880) {
            .footer-logo-negative-margin{
                    margin-top: -17px;
            }
        }

        @media screen and (min-width: $break-mobile) and (max-width: $break-ipad880) {
            .footer-socialmedia-btn-section-margin-top{
                    margin-top:40px
            }
        }
    
        @media screen and (max-width: $break-ipad880) {
            .footer-socialmedia-section-desktop{
                display: none;
            }
        }
        @media screen and (min-width: $break-ipad880) {
            .footer-socialmedia-section-mobile{
                    display: none;
                }
        }
        div{
            padding: 0px;
            flex: 0 0 20%;
            max-width: 20%;
            @media screen and (max-width: $break-ipadproportrait) {
                flex: 0 0 33%;
                 max-width: 33%;
            }
            @media screen and (max-width: $break-mobile) {
                flex: 0 0 50%;
                 max-width: 50%;
            }
            @media screen and (max-width: $break-mobilesmall) {
                flex: 0 0 100%;
                max-width: 100%;
            }
            h3{
                min-height: 30px;
                font-size: 16px;
                font-weight: 700;
            }
            ul{
                padding: 0px;
                li{
                    list-style-type: none;
                    font-size: 16px;
                    margin-bottom: 10px;
                    a{
                        color: #7D8597;
                        &:hover{
                            text-decoration: none;
                        }
                    }
                    span{
                        color: $dargreen;
                        font-weight: 600;
                    }
                    button{
                        width: 100%;
                        height: 50px;
                        border-radius: 6px;
                        border:none;
                        box-shadow: none;
                        font-size: 16px;
                        color: $white;
                        i{
                            margin-right: 10px;
                            font-size: 20px;
                        }
                        &.apple{
                            background-color: #25BDEF;
                          
                            
                        }
                        &.gplay{
                            background-color: #FF6240;
                        }
                        &:focus{
                            outline: none;
                            box-shadow: none;
                            border:none;
                        }
                    }
                    &.socialWrap{
                        display: flex;

                        a{
                            
                            margin-right: 5px;
                            display:flex;
                            justify-content: center;
                            align-items: center;
                            width: 40px;
                            height: 40px;
                            border-radius: 6px;
                            i{
                                color: $white;
                                font-size: 22px;
                            }
                            &:nth-child(1){
                                background: linear-gradient(
                                    45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
                            }
                            &:nth-child(2){
                                background: #3b5998;
                            }
                            &:nth-child(3){
                                background: #FF0000;
                            }
                            &:nth-child(4){
                                background: #1DA1F2;
                            }
                        }
                       
                    }
                }
            }
        }

        .footer-log-tablet-view{
            @media screen and (min-width: $break-mobile) and (max-width: $break-ipad880) {
                width: 223px;
            }
        }
    }

}
.Copywrite{
    background-color: #f1f7ed;
    border-top: 1px solid #ddd;
    position: relative;
    z-index: 98;
    .InnerWrap{
        display: flex;
        width: 1200px;
        max-width: 100%;
        padding: 25px 15px;
        margin:0 auto;
        border:none;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 400px) {
            padding: 25px 5px;
            div {
                text-align: center;
                padding: 0;
            }
        }
        p{
            margin-bottom: 0px;
        }
        button{
            border: none;
            box-shadow: none;
            color: #3c4b64;
            background-color: transparent;
            text-transform: uppercase;
            @media screen and (max-width: $break-mobile) {
                position: absolute;
                bottom: 55px;
                right: 10px;
                i {
                   font-size: 25px !important;
                }
            }
            &:focus{
                border: none;
                outline: none;
            }
            i{
                color: $white;
                font-size: 10px;
                background-color: #25BDEF;
                padding: 5px;
                border-radius: 50%;
                margin-left: 5px;
            }
        }
        .footer-top-bar-icon-text{
            @media screen and (max-width: $break-mobile) {
                display: none;
            }
        }
    }
}