.CourseDetail {
    position: relative;
    z-index: 9;
    min-height: 800px;
    overflow-y: auto;
    &:after{
        content: '';
        position: absolute;
        height: 300px;
        width: 300px;
        top: 50%;
        right: 0px;
        left: 0;
        margin: 0 auto;
        background-image: url('../assets/stethologin.png');
       z-index: -1;
    }
    .PreviewDetailBox{
        padding-right: 15px;
        padding-left: 15px;
        .col-md-6{
            @media screen and (max-width: $break-ipadproportrait) {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }
    p{
        font-size: 16px;
        font-weight: 400;
        color: #9098A7;
        span{
            font-weight: 500;
            color: #334565;
        }
    }
    img{
        max-width: 100%;
    }
    .rating{
        i{
            font-size: 16px;
            color: #9098A7;
            cursor: pointer;
            margin-right: 5px;
            &.selected{
                color: #d97d26;
            }
        }
        span{
            &.count{
                font-size: 16px;
                display: inline-block;
                margin-left: 10px;
                color: #9098A7;
            }
        }
        p{
            margin-bottom: 0px;
            color: #334565;
            span{
           
                margin-left: 5px;
                color: #9098A7;
                font-size: 16px;
            }
        }
        button{
            padding-left: 0px;
            &:focus, &:active, &:visited{
                outline: none;
                box-shadow: none;
            }
        }
    }
    .BtnWrap{
        text-align: right;
        @media screen and (max-width: $break-ipad) {
            text-align: left;
        }
        button{
            background-color: #78af4d;
            color: $white;
            font-size: 20px;         
            border-color: $dargreen;
            width: 120px;
            height: 40px;
            border-radius: 6px;
            margin-bottom: 20px;
            font-size: 16px;
            margin-top: 10px;
            &:hover, &:focus{
                box-shadow: none;
                outline: none;
            }
            @media only screen and (max-width: $break-ipad) {
                margin-right: 10px;
            }
            @media only screen and (min-width: 1782px) {
                margin-right: 10px;
            }
        }
    }
    .handout{
        h4{
            color: $dargreen;
            font-size: 18px;
            font-weight: 500;
            cursor:pointer;
        }
        h4:hover{
            color: #8fe5a5;
            font-size: 18px;
            font-weight: 500;
            cursor:pointer;
        }
    }
    .handout-dropdown{
        .dropdown-menu{
            max-height: 300px;
            overflow: auto;
        }
        .dropdown-item:active{
            background-color: $dargreen !important;
        }
    }
    .CardBox{
        box-shadow: 0px 2px 20px 0px #ddd;
        border-radius: 20px;
        padding-bottom: 20px;
        margin-bottom: 20px;
        text-align: center;
        background-color: #fff;
        z-index: 9;
        position: relative;
        min-height: 350px;
        padding-bottom: 65px;
        .btnWrapLendingLearn {
            position:absolute;
            left: 0;
            right: 0;
            bottom: 20px;
        }
        .image-container {
            //min-height: 270px;
        }
        img{
            max-width: 100%;
            height: 200px;
            min-width: 100%;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            @media (min-width:$break-ipadproportrait) and (max-width:$break-belowlaptop) {
                max-height: 220px !important;
            }
        }
        h4{
            text-align: center;
            margin-top: 10px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            padding: 0px 10px;
        }
        p{
            text-align: center;
            font-weight: 500;
            color: #888b92;
        }
        .btnWrap{
            text-align: center;
            padding: 0px 15px;
            background-color: $dargreen;
            a{
                background-color: $dargreen;
                color: $white;
                padding: 10px 25px;
                border-radius: 6px;
                font-size: 16px;
                box-shadow: none;
                border: none;
                font-weight: 400;
                display: block;
                width: 200px;
                max-width: 100%;
                margin:0 auto;
                &:hover{
                    text-decoration: none;
                }
            }
        }
    }
}
.image-container {
    //min-height: 270px;
    @media (min-width:$break-ipadproportrait) and (max-width:$break-belowlaptop) {
                min-height: 220px;
    }
}
.handout {
    h4 {
        cursor: pointer;
        width: fit-content;
    }
}
.rating-review {
    margin-top: 20px;
    h6 {
        font-size: 0.925rem;
        margin-bottom: 0;
        margin-top: 12px;
    }
    p {
        margin-bottom: 6px;

    }
}
.ImageWrap {
    iframe {
        width: 100%;
    }
}
.line-break { 
    @media (max-width:$break-belowlaptop) {
        display: block !important;
    }
}
.filterDropdownCourses{
    @media (max-width: $break-laptoplg1440) {
           float: unset !important;
    }
}

  /* Firefox 4-18 */
  .react-datepicker-wrapper input:-moz-placeholder {
    color: #aeaeae !important;
    opacity: 1;
  }
  /* Firefox 19+ */
  .react-datepicker-wrapper  input::-moz-placeholder {
    color: #aeaeae !important;
    opacity: 1;
  }
.text-area {
    textarea {
        max-height: 380px;
        @media screen and (max-height:767px) {
            max-height: 280px;
        }
        @media screen and (max-height:540px) {
            max-height: 130px;
        }
    }
}
.handout-model {
    .modal-dialog {
        padding: 0.925rem;
    }
}
@media screen and (max-width:$break-belowlaptop) and (min-width:$break-ipad) {
    .course-preview {
        display: flex;
        flex-direction: column-reverse;
        .col-lg-6.col-sm-12 {
            max-width: 100%;
            flex: 0 0 100%;
            .BtnWrap {
                text-align: left;
                display: flex;
                grid-gap: 10px;
            }
        }
    }
}
@media screen and (max-width:$break-mobile){
    .filterDropdownCourses {
        .filter-container {
            .select__control {
                min-width: 14.5rem !important;
            }
        }
    }
}

// .reactPlayerWrapperCourse iframe{
//     height: 360px;
//     @media screen and (min-width: $break-mobile) and (max-width: $break-ipadproportrait) {
//              height: 100% !important;
//              min-height: 300px;
//         }
//         @media screen and (max-width: $break-mobile){
//             min-height: 360px;
//             // height: auto;
//         }

//     @media screen and (min-width: $break-ipadprolandscape) and (max-width: $break-belowlaptop) {
//         height: 360px !important;
//       }
//     }