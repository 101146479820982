.LayoutWrap{
    .card{
        border:none;
        .card-body{
            .HeadingWrap{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;
                z-index: 9;
                position: relative;

                h1{
                    // width: 75%;
                    a{
                        color: #3c4b64;
                        &:hover{
                            text-decoration: none;
                        }
                    }
                    @media screen and (max-width: $break-laptoplg1440) {
                        font-size: 30px;
                    }
                    @media screen and (max-width: $break-ipad) {
                        font-size: 25px;
                    }
                    @media screen and (max-width:$break-belowlaptop) {
                        width: 100%;
                    }
                }
                @media screen and (max-width:$break-belowlaptop) {
                    display: block;
                }
                .BreadCRumb{
                    p{
                        margin-bottom: 0px;
                        font-size: 16px;
                        i{
                            font-size: 12px;
                            margin: 0px 5px;
                        }
                        a{
                            color: #000;
                        }
                        &.FilterDropdown{
                            .form-group{
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                label{
                                    margin-bottom: 0px;
                                    margin-right: 10px;
                                    min-width: 75px;
                                    font-weight: 500;
                                }
                                select{
                                    border-radius: 100px;
                                    border-color: #3c4b64;
                                    max-width: 140px;
                                    max-width: 200px;
                                    padding-right: 30px;
                                    &:focus{
                                        box-shadow: none;
                                    }
                                }
                            }
                            
                        }
                    }
                }
            }
            .Subheading{
                h3{
                    font-size: 20px;
                .BookMark{
                    i{
                        color: #f2a553;
                    }
                }
                }

             
            }
            .TabsWrap{
                ul{
                    &.nav {
                        border: none;
                        margin-bottom: 30px;
                        li{
                            a{
                                color: #959dab;
                                font-size: 16px;
                                position: relative;
                                border: none;
                                &.active{
                                    background-color: transparent;
                                    border: none;
                                    color: $dargreen;
                                    &:after{
                                        content: '';
                                        position: absolute;
                                        bottom: 0px;
                                        background-color: $dargreen;
                                        width: 75%;
                                        height: 2px;
                                        left: 0;
                                        right: 0;
                                        margin: 0 auto;
                                    }
                                }
                                &:hover{
                                    border:none;
                                    text-decoration: none;
                                }
                                @media screen and (max-width: $break-ipad) {
                                    padding: 0.5rem 0.5rem;
                                    font-size: 14px;
                                    
                                  }
                            }
                        }
                    }
                }
                .tab-content{
                    .tab-pane {
                        .row{
                            .col-md-4{
                                @media screen and (max-width: $break-ipadproportrait) {
                                    flex: 0 0 50%;
                                    max-width: 50%;
                                }
                                @media screen and (max-width: $break-ipad) {
                                    flex: 0 0 100%;
                                    max-width: 100%;
                                }
                                a{
                                    &:hover{
                                        text-decoration: none;
                                    }
                                    .ImageBox{
                                        position: relative;
                                        margin-bottom: 30px;
                                       
                                        .ImageArea{
                                            // background-color: #ddd;
                                            max-height: 280px;
                                            overflow: hidden;
                                            border-radius: 20px;
                                            img{
                                                max-width: 100%;
                                                max-height: 230px;
                                                @media screen and (max-width: $break-laptoplg1440) {
                                                    max-height: 170px;
                                                }
                                                @media screen and (max-width: $break-ipad) {
                                                    max-height: none;
                                                }
                                            }
                                            text-align: center;
                                        }
                                        .TextOverlay{
                                            position: relative;
                                           top: -30px;
                                            background-color: #fff;
                                            padding: 10px;
                                            left: 0;
                                            right: 0;
                                            width: 80%;
                                            margin: 0 auto;
                                            border-radius: 20px;
                                            text-align: center;
                                            min-height: 75px;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            box-shadow: 0px 0px 20px 0px #ddd;
                                            color: #7D8597;
                                            font-size: 16px;
                                            word-break: break-all;
                                            @media screen and (max-width: $break-belowlaptop) {
                                                position: relative;
                                                bottom: unset;
                                                width: 95%;
                    
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
           
            .AllCourseGrid{
                h5{
                    font-size: 16px;
                    font-weight: 700;
                    margin-bottom: 15px;
                }
                .row{
                    .col-sm-6 {
                        img{
                            border-radius: 20px;
                            max-height: 270px;
                            @media (min-width:$break-ipadproportrait) and (max-width:$break-belowlaptop) {
                                max-height: 220px !important;
                            }
                        }
                        p{
                            text-align: center;
                            margin-top: 10px;
                            font-size: 16px;
                            padding: 0 8px 0 8px;
                        }
                        a{
                            color: #959dab;
                            font-size: 16px;
                            position: relative;
                            border: none;
                            &.active{
                                background-color: transparent;
                                border: none;
                                color: $dargreen;
                                &:after{
                                    content: '';
                                    position: absolute;
                                    bottom: 0px;
                                    background-color: $dargreen;
                                    width: 75%;
                                    height: 2px;
                                    left: 0;
                                    right: 0;
                                    margin: 0 auto;
                                }
                            }
                            &:hover{
                                border:none;
                                text-decoration: none;
                            }
                            @media screen and (max-width: $break-ipad) {
                                padding: 0.5rem 0.5rem;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
    .btnWrapLendingLayout{
        > a{
          background-color: #78af4d;
    color: #fff;
    padding: 10px 25px;
    border-radius: 6px;
    font-size: 16px;
    box-shadow: none;
    border: none;
    font-weight: 400;
    display: block;
    width: 200px;
    max-width: 100%;
    margin-top: 20px;
    text-align: center;
        }
      }
}
.ContentRight{
    z-index: 99;
}