.EventWrap{
    border:none;
    .HeadingWrap{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        z-index: 9;
        position: relative;

        h1{
            a{
                color: #3c4b64;
                &:hover{
                    text-decoration: none;
                }
            }
            @media screen and (max-width: $break-laptoplg1440) {
                font-size: 30px;
            }
            @media screen and (max-width: $break-ipad) {
                font-size: 25px;
            }
        }
        @media screen and (max-width:$break-ipad880) {
            display: block;
        }
        .BreadCRumb{
            p{
                margin-bottom: 0px;
                font-size: 16px;
                i{
                    font-size: 12px;
                    margin: 0px 5px;
                }
                a{
                    color: #000;
                }
             
            }
        }
    }
    .Subheading{
        h3{
            font-size: 20px;
        .BookMark{
            i{
                color: #f2a553;
            }
        }
        }

     
    }
   
    .ListWrap{
        ul{
            padding: 0px;
            position: relative;
            z-index: 99;
            li{
                list-style-type: none;
                box-shadow: 0px 2px 20px 0px #ddd;
                border-radius: 20px;
                padding: 10px;
                margin-bottom: 30px;
                .ImageWrap{
                    img{
                        max-width: 100%;
                    }
                }
                .ContentWrap{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    .TextWrap{
                        h3{
                            font-size: 16px;
                            margin-bottom: 5px;
                        }
                        p{
                            margin-bottom: 5px;
                        }
                    }
                    .ButtonWrap{
                        margin-top: 20px;
                        button{
                            background-color: #78af4d;
                            color: $white;
                            font-size: 16px;
                            border-color: $dargreen;
                            width: 150px;
                            height: 45px;
                            border-radius: 6px;
                            margin-bottom: 20px;
                            
                            &:nth-child(1){
                                margin-right: 10px;
                            }
                            
                        }
                    }
                }
                .col-md-2{
                    @media screen and (max-width: $break-laptop1365) {
                        flex: 0 0 40%;
                        max-width: 40%;
                    }
                    @media screen and (max-width: $break-ipad) {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }
                &:nth-child(1){
                    .TextWrap{
                        &:after{
                            content: '';
                            height: 70px;
                            width: 70px;
                            position: absolute;
                            left: 80px;
                            background-image: url('../assets/circlepatch.png');
                            background-size: contain;
                            top: 60px;
                            background-repeat: no-repeat;
                            @media screen and (max-width: $break-ipad) {
                                display: none;
                            }
                        }
                        &:before{
                            content: '';
                            height: 70px;
                            width: 70px;
                            position: absolute;
                            right: 50%;
                            background-image: url('../assets/polyleftpatch.png');
                            background-size: contain;
                            top: 60px;
                            background-repeat: no-repeat;
                            @media screen and (max-width: $break-laptop1365) {
                                right: 20px;
                            }
                            @media screen and (max-width: $break-ipad) {
                                display: none;
                            }
                        }
                    }
                   
                }
                &:nth-child(2){
                    .TextWrap{
                        &:after{
                            content: '';
                            height: 70px;
                            width: 70px;
                            position: absolute;
                            left: 180px;
                            background-image: url('../assets/polyleftpatch.png');
                            background-size: contain;
                            top: 60px;
                            background-repeat: no-repeat;
                            @media screen and (max-width: $break-ipad) {
                                display: none;
                            }
                        }
                        &:before{
                            content: '';
                            height: 70px;
                            width: 70px;
                            position: absolute;
                            right: 30%;
                            background-image: url('../assets/circlepatch.png');
                            background-size: contain;
                            top: 60px;
                            background-repeat: no-repeat;
                            @media screen and (max-width: $break-laptop1365) {
                                right: 20px;
                            }
                            @media screen and (max-width: $break-ipad) {
                                display: none;
                            }
                        }
                    }
                }   
                &:nth-child(3){
                    .TextWrap{
                        &:after{
                            content: '';
                            height: 70px;
                            width: 70px;
                            position: absolute;
                            left: 180px;
                            background-image: url('../assets/patchbite.png');
                            background-size: contain;
                            top: 60px;
                            background-repeat: no-repeat;
                            @media screen and (max-width: $break-ipad) {
                                display: none;
                            }
                        }
                        &:before{
                            content: '';
                            height: 70px;
                            width: 70px;
                            position: absolute;
                            right: 30%;
                            background-image: url('../assets/polybite.png');
                            background-size: contain;
                            top: 60px;
                            background-repeat: no-repeat;
                            @media screen and (max-width: $break-laptop1365) {
                                right: 20px;
                            }
                            @media screen and (max-width: $break-ipad) {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
    .fc-h-event {
        .fc-event-main-frame{
            display: block;
            .fc-event-title-container{
                .fc-event-title{
                    white-space: break-spaces;
                }
            }
        }
    }
    .fc-media-screen {
        .fc-toolbar-title{
            padding: 0 8px 0 8px;
            }
        @media screen and (max-width:$break-ipad) {
        .fc-button {
        line-height: 1;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 4px;
        &.fc-next-button {
            padding-right: 0.15rem;
            padding-left: 0.15rem;
        }
        &.fc-prev-button {
            padding-right: 0.15rem;
            padding-left: 0.15rem;
        }
        }
        .fc-toolbar-title {
            font-size: 0.825rem;
            white-space: nowrap;
        }
        }
    }
}
.fc-timeGridWeek-view {
    overflow: auto;
    .fc-scrollgrid {
        min-width: 423px;
        .fc-scroller {
            // overflow: auto !important;
            table {
                min-width: 420px !important;
            }
        }
    }
}

@media screen and (max-width:$break-mobile){
.event-calendar-card-12{
            padding: 1rem !important;
    }
    
    .eventDetailDropdownCSS {
        .filter-container {
            .select__control {
                min-width: 11rem !important;
            }
        }
    }
}