body{
  background-color: #fff !important;
}
.c-app{
  background-color: #fff !important;
}
// Here you can add other styles
.help-block {
  color: red !important;
}

.modal-dialog {
  .BtnWrap {
    button {
      background-color: #78af4d;
      color: $white;
      font-size: 20px;
      border-color: $dargreen;
      width: 120px;
      height: 40px;
      border-radius: 6px;
      font-size: 16px;
      margin-top: 10px;
      &:nth-child(1) {
        margin-right: 10px;
      }
      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  }
}
.all-course-container {
  .course-category {
    padding: 10px;
    background-color: #f2f2f2;
    border-radius: 4px;
    margin: 20px 0;
  }
  .all-course-section {
    border-radius: 20px;
    border: 1px solid #f2f2f2;
    @media (min-width:$break-laptop1365){
      min-height: 260px !important;
  }
  //   @media (max-width:$break-laptop1365){
  //     min-height: 300px !important;
  // }
    img {
      border-radius: 0 !important;
      border-top-right-radius: 20px!important;
      border-top-left-radius: 20px!important;
    }
    h3 {
      font-size: 18px !important;
    }
  }
}
.btn-cancel {
  background-color: $dargreen;
  color: $white;
}
.choose-file {
  padding: 18px 10px !important;
  cursor: pointer;
}
.NotificationListPage{
  > .col-md-12{
    ul{
      li{
        &.unreadNotif {
          background-color: #e1e1e1;
          color: #7e7a7a !important
        }
      }
    }
  }
}
.mr-unset{
  margin:unset !important;
}
.responsive-btn {
  display: flex;
  justify-content: center;
  grid-gap: 5px;
  &.buttonWrap {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .card-header{
    padding-left: 0px;
  }
}
