.NavigationBar {
  border: none;
  margin-bottom: 0px;
  @media screen and (max-width: $break-ipad) {
    margin-bottom: 0px;
  }
  .card-body {
    padding: 0px;
    .navbar {
      height: 80px;
      border-bottom: none;
      box-shadow: none;
      @media screen and (max-width: $break-ipad) {
        height: auto;
      }
      .navbar-brand {
        color: $black;
        a {
          &:focus {
            outline: none;
            box-shadow: none;
            border: none;
          }
        }
      }
      .navbar-toggler {
        @media screen and (max-width: $break-ipad) {
          background-color: $dargreen;
        }
        &:focus {
          box-shadow: none;
          border: none;
          outline: none;
        }
      }
      .navbar-collapse {
        @media screen and (max-width: $break-ipad) {
          background-color: $white;
        }
        ul {
          a {
            color: $black !important;
            margin: 0px 10px;
            font-size: 16px;
            @media screen and (min-width: $break-mobile) and (max-width: $break-ipad880) {
              // margin: 0px 0px;
            }
            &:hover {
              color: $black !important;
            }
          }
          button {
            &.Login {
              background-color: $white;
              border: 2px solid $red;
              color: $red;
              border-radius: 6px;
              margin-right: 20px;
              margin-left: 20px;
              font-size: 16px;
              @media screen and (min-width: $break-mobile) and (max-width: $break-ipad880) {
                margin-right: 10px;
                margin-left: 10px;
              }
              @media screen and (max-width: $break-ipad) {
                background-color: $white;
                color: $black;
                text-align: left;
                width: auto;
                border-color: $white;
                margin: 0px;
                padding-left: 8px;
              }
            }
            &.Register {
              background-color: $dargreen;
              border: 2px solid $dargreen;
              color: $white;
              border-radius: 6px;
              font-size: 16px;
              @media screen and (max-width: $break-ipad) {
                background-color: $white;
                color: $black;
                text-align: left;
                width: auto;
                border-color: $white;
                padding-left: 8px;
              }
            }
            &:focus {
              outline: none;
              box-shadow: none;
            }
          }
        }
      }
    }
  }

  .card-body {
    .navbar {
      .navbar-collapse {
        ul {
          .notificationDropdown {
            position: relative;
            button {
              background-color: transparent !important;
              border-color: transparent !important;
              &:focus,
              &:active,
              &:hover {
                background-color: transparent !important;
                border-color: transparent !important;
                box-shadow: none !important;
                outline: none !important;
              }
              &:after {
                display: none;
              }
              i {
                font-size: 20px;
              }
            }

            .badge {
              position: absolute;
              right: -1px;
              padding: 4px;
              border-radius: 50%;
              height: 20px;
              font-size: 10px;
              width: 20px;
              display: -webkit-flex;
              display: flex;
              -webkit-align-items: center;
              align-items: center;
              -webkit-justify-content: center;
              justify-content: center;
              top: -2px;
              z-index: 98;
              @media screen and (max-width: $break-mobile) {
                right: unset;
                left: 20px;
              }
            }

            .dropdown-menu {
              a {
                width: 350px;
                font-size: 13px !important;
                word-wrap: break-word;
                white-space: break-spaces;
                &.AllNotification {
                  color: #fff !important;
                  background-color: #78af4d;
                  margin: 0 auto;
                  text-align: center;
                  justify-content: center;
                  &:focus,
                  &:hover {
                    color: #fff !important;
                    background-color: #78af4d;
                  }
                }
                &:focus,
                &:active,
                &:focus {
                  background-color: transparent !important;
                }
                &.unreadMenuNotif {
                  background-color: #e1e1e1;
                  color: #7e7a7a !important;
                  font-weight: bold;
                }
              }
            }
          }
        }
      }
    }
  }

  .header-log-tablet-view {
    @media screen and (min-width: $break-mobile) and (max-width: $break-ipad880) {
      width: 200px;
    }
    @media (max-width: $break-ipad830) {
        width: 185px;
      }
      @media (min-width: $break-ipad768) and (max-width: 829px) {
        width: 170px;
      }
     
     
  }
}
