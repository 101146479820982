// Variable overrides
//font awesome 
@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

//Color

$red: red;
$dargreen: #78af4d;
$white: #fff;
$black: #000;


//Screen Breakpoints

$break-laptoplg: 1640px;
$break-abovelaptop: 1590px;
$break-laptoplg1440: 1440px;
$break-laptop: 1366px;
$break-laptop1365: 1365px;
$break-belowlaptop: 1280px;
$break-MDPI: 1180px;
$break-ipadprolandscape: 1024px;
$break-ipadproportrait: 991px;

$break-ipad880: 880px;
$break-ipad830: 830px;
$break-ipad768: 768px;
$break-ipad: 767px;
$break-mobile: 667px;
$break-mobile580: 580px;
$break-mobilesmall: 448px;
$break-mobilemini: 320px;


//placeholder mixin
@mixin placeholder {
    &::-webkit-input-placeholder {
      @content;
    }
  
    &:-moz-placeholder {
      @content;
    }
  
    &::-moz-placeholder {
      @content;
    }
  
    &:-ms-input-placeholder {
      @content;
    }
  }

  input::-ms-reveal, input::-ms-clear {
    display: none;
}
.mb-2{
  margin-bottom: 20px;
}