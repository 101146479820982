
.ThemeBtnGreen{
    background-color: #78af4d;
    color: $white;
    font-size: 20px;
    border-color: $dargreen;
    height: 60px;
    border-radius: 6px;
    margin-bottom: 20px;
    margin-top: 30px;
    &:hover, &:focus, &:active{
        background-color: #78af4d !important;
        color: $white;
        border-color: $dargreen !important;
        box-shadow: none !important;
    }
}
.text-left{
    text-align: left;
}
.btnlg {
    max-width: 300px;
    width: 100%;
}
.CustomSelect{
    position: relative;
    select{
        -webkit-appearance: none;
        position: relative;
        background-color: transparent;
        z-index: 11;
        &:hover, &:focus{
            background-color: transparent;
        }
       
    }
    &:after{
        font-family: 'FontAwesome';
        content: "\f078";
        position: absolute;
        right: 10px;
        top: 8px;
        font-size: 13px;
    }
}
select{
    &:focus{
        border-color: initial !important;
       
    }
}
.no-content {
    height: calc(100vh - 500px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 33px;
    opacity: 0.4;
    background-color: #fff;
}
.EmailIcon {
    height: 85px;
    h4 {
        margin-bottom: 14px;
    }
}
.other-social-icon {
    h4 {
        font-size: 1rem;
        text-align: center;
        
    }
    .SocialIcon {
        h4 {
            text-align: left;
            margin-left: 10px;
        }
    }
}
.btn-create {
    background-color: $dargreen;
    color: #fff;
    &:hover {
        color: #fff;
    }
}
.choose-file .form-group{
    border-color: #707070;
    height: 60px;
    border-radius: 6px;
    box-shadow: 0px 3px 5px 3px #ececec;
    background-color: #fff;
    width: 100%;
    border: none;
}
.publicationBlock.all-course-section.presentation {
    @media (max-width:1366px) {
        min-height: 330px;
    }
    .ImageWrap {
        min-height: 270px;
        @media (max-width:1366px) {
            min-height: 200px;
        }
    }
    img {
        max-height: 270px;
    }
    p, h3 {
        padding: 0 5px;
        text-align: center;
    }
}
.profile-choose {
    .form-control {
        cursor: pointer;
        padding: 18px 10px !important;
    }
}
.FilterDropdown {
    // float: right;
    .filter-container {
        display: flex;
        align-items: center;
        p {
            margin-right: 10px;
            margin-bottom: 1px;
        }
        .basic-single {
            z-index: 999;
        }
       .select__control {
           max-width: 16rem;
           min-width: 16rem;
           @media screen and (max-width:$break-mobile){
               min-width: 12.5rem;
           }
           border-radius: 50px;
           background-color: transparent;
               z-index: 9999;
           span.select__indicator-separator {
               width: 0;
           }
           .select__indicators{
            .select__indicator{
                svg{
                    display: none !important;
                }
            }
           }
           
       }
    }
}
.line-break { 
    @media (max-width:$break-laptop1365) {
        display: block !important;
    }
}
.min-w-50 {
    min-width: 20%;
}
.no-content-data {
    height: calc(100vh - 500px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.4;
    background-color: #fff;
    text-align: center;
    h4 {
        font-size: 30px;
    }
    p {
        font-size: 25px;
    }
}
.basic-single  {
    .select__indicator {
        svg {
            display: none;
        }
    }
    &:after {
      content: "";
      width: 20px;
      height: 10px;
      background-image: url("../assets/down-filled-triangular-arrow.png");
      background-size: contain;
      right: 10px;
      top: 15px;
      position: absolute;
      background-repeat: no-repeat;
    }
}

body.ModalOpen{
overflow: hidden;
}
.NorecordData{
    text-align: center;
}
.mandatory{
    .form-group{
        label{
            width:max-content;
            position: relative;
            &:after{
                content: '*';
                color: red;
                position: absolute;
                right: -7px;
                top: 0px;
            }
        }
    }
}

.basic-single .select__indicator svg{
    display: block;
}

.Toastify__toast-container{
    .Toastify__toast--success{
        background-color: #78af4d !important;
    }
}
.reactPlayerWrapper{
    .react-player{
        width: 100% !important;
        margin-bottom: 20px;
        // height: 100% !important;
    }
}
.modal{
    .modal-content{
        .modal-footer{
            button{
                margin: 0px 5px;
                &.BtnThemeDefault{
   
                    background-color: #78af4d !important;
                    color: $white !important;
                    font-size: 20px;
                    border-color: $dargreen;
                    width: 120px;
                    height: 40px;
                    border-radius: 6px;
                    margin-bottom: 20px;
                    font-size: 16px;
                    margin-top: 10px;
                    &:hover, &:focus{
                        box-shadow: none;
                        outline: none;
                    }
              
                }
                &.btnThemecancel{
                    background-color: transparent !important;
                    color: #000 !important;
                    font-size: 20px;
                    border-color: #000;
                    width: 120px;
                    height: 40px;
                    border-radius: 6px;
                    margin-bottom: 20px;
                    font-size: 16px;
                    margin-top: 10px;
                    &:hover, &:focus{
                        box-shadow: none;
                        outline: none;
                    }
                }
            }
            
        }
    }
}
.on{
    padding-right: 20px;
}
.AboutUsPage{
    position: relative;
    p{
        font-size: 16px;
        font-weight: 400;
        color: #9098A7;
    }
    button {
     right:11px;
     top: 8px;
     position:absolute;
     z-index: 9999;
    }
    .card {
        .card-header, .card-body{
            padding: 0 !important;
            border-bottom: none !important;
        }
        .card-body{
            padding-top: 20px !important;
        }
    }

    .card {
      @media screen and (max-width: $break-ipad) {
        .card-header {
         padding-left: 0;
         }
         .card-body {
             margin-top: 15px;
         }
        }
        .col-12.col-md-12 {
            h2 {
                margin-bottom: 0px !important;
                @media (max-width: $break-ipad) {
                    margin-bottom: 10px !important;
                }
            }
        }
    }
}
.TermsConditions{
    button {
        right:20px;
        top: 22px;
        position:absolute;
        z-index: 9999;
       }
       .card {
        .card-header, .card-body{
            padding: 0 !important;
            border-bottom: none !important;
        }
        .card-body{
            padding-top: 20px !important;
        }
    }

}
.input-group-prepend .btn, .input-group-append .btn{
    z-index: 0;
}
.MemebershipSelect{
    label{
        font-size: 16px;
        
    }
}
.SecurCode{
    .form-group{
        position:relative;
        .help-block{
            position:absolute;
            bottom:-22px;
            left:0px;
        }
    }
}
.profile-choose {
    position: relative;
    .static-message {
        position: absolute;
        top: 42%;
        left: calc(0% + 120px);
        overflow:hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: calc(100% - 140px);

    }

}
.membership-container {
    .SelectWrap {
        label {
            font-size: 16px;
        }
        select {
            padding-right: 30px;
            @media only screen and (max-width: 990px){
                padding-right: 0px !important;
               }
        }
    }
    .plan-wrapper {
        padding:0 10px;
        p {
            margin-top: 0;
            padding: 6px;
            background-color: #78af4d;
            color: #fff;
            font-weight: 500;
            border-radius: 4px;
            padding-left: 12px;
            margin-left: 4px;
            position: relative;
            &::before {
               content: '';
               position: absolute;
               background-color: #78af4d;
               width: 5px;
               height: 5px;
               border-radius: 100%;
               top: 44%;
               left: -13px;
            }
        }
    }
    .no-record-found {
        text-align: center;
        padding: 10px;
    }
}
.playButton{
    color: #78af4d;
    // height: 25px;
    // width: 25px;

}
.playclass{
    border: none;
    border-radius: 50px;
    height: 50px;
    width: 50px;
}
.modal-footer {
    button[type='button']{
        &.footerBtnGreen{
            background-color: #78af4d !important;
            color: $white !important;
            border-color: $dargreen !important;
            border-radius: 6px;
            &:hover, &:focus, &:active{
                background-color: #78af4d !important;
                color: $white;
                border-color: $dargreen !important;
                box-shadow: none !important;
            }
        }
    }
}
.SelectWrap {
    @media screen and (max-width: $break-mobile) {
        &::after {
          right: 10px !important;
        }
    }
    select {
      padding-right: 45px !important;
    }
}
.break-word {
    word-break: break-all;
}


.new-select-css {
    max-width: 16rem;
    min-width: 16rem;
    border-radius: 50px;
    background-color: transparent;
    min-height: 38px;
}

.new-select-css .Select-control{
    border-radius: 25px;
}

.async-dropdown-menu{
    input {
              box-shadow: none !important;
      }
}

.main-div-wrapper {
        @media screen and (min-width: 1280px){
            .main-dashboard-rightside-content{overflow: hidden;padding-top: 20px;}
        }

        @media screen and (min-width: 768px) and (max-width: 1002px){
        .left-sidebar-dashboard{
            max-width: 33% !important;
        }
        .main-dashboard-rightside-content{
            max-width: 67% !important;
        }
       
    }
}
@media screen and (max-width: 400px){
.offeredCourseHeading h1{
        font-size: 1.8rem;
    }
}

#aboutUsData {
    h1, span, p , h2{
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    }
    h2 {
        span {
        color: #3c4b64 !important;
        }
    }
}

/* Only for Safari 11+ */
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    .profile-choose .static-message{
        @media screen and (max-width: $break-mobile){
            left: calc(0% + 140px);
            max-width: calc(100% - 160px);
        }
    }
}}


.publicationBlock.all-course-section.presentation {
    @media (max-width:1920px) {
        margin-top: 20px;
    }
}